import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  variants: {
    primary: {
      field: {
        backgroundColor: 'input.bg',
        width: '100%',
        height: '48px',
        borderTop: '0',
        borderLeft: '0',
        borderRight: '0',
        borderBottom: '3px solid',
        borderColor: 'input.border',
        padding: '12px 16px',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
        color: 'input.text',
        borderRadius: '8px',
        _placeholder: {
          color: 'input.placeholder',
        },
        _focus: {},
        _invalid: {
          borderColor: 'input.invalid',
          boxShadow: 'none',
        },
      },
    },
    readonly: {
      field: {
        backgroundColor: 'input.placeholder',
      },
    },
  },
};

export default Input;
