import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { API_BASE_URL } from '@/utils/constants';

export const prepareHeaders = (headers: Headers) => {
  // const jwt = getStorageItem<string>(StorageKeysEnum.jwt);

  // if (jwt) {
  //   headers.set('authorization', `Bearer ${jwt}`);
  // }

  return headers;
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders,
});
export const baseQueryWithRedirect: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = (await baseQuery(args, api, extraOptions)) as any;
  if (result.error?.status === 401 || result?.error?.originalStatus === 401) {
    localStorage.removeItem(StorageKeysEnum.jwt);
  }
  return result;
};
