import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { RootState } from '@/store/index';

interface IAppSliceState {
  shouldUpdateProfile: boolean;
}

const initialState: IAppSliceState = {
  shouldUpdateProfile: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    SET_SHOULD_UPDATE_PROFILE: (state, action: { payload: boolean }) => {
      state.shouldUpdateProfile = action.payload;
    },
  },
});

export default appSlice.reducer;
export const { SET_SHOULD_UPDATE_PROFILE } = appSlice.actions;
export const selectAppStore = (state: RootState) => state.app;
export const useAppStore = () => useSelector(selectAppStore);
