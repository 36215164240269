import { useEffect } from 'react';

import { Button, Flex, Heading } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/localization/index';
import { useUpdateUserProfileMutation } from '@/services/api.service';

import { useUserContext } from '@/context/user.context';

import FormCountrySelect from '@/elements/FormCountrySelect/index';
import FormDebounceInput from '@/elements/FormDebounceInput/index';
import FormInput from '@/elements/FormInput/index';

import { ToastTypesEnum } from '@/enum/toastTypes.enum';

import { useToast } from '@/hooks/useToast';

import { dirtyValues } from '@/utils/form';

import { profileSchema } from '@/validations/profile.schema';

export const ProfileForm = () => {
  const [triggerUpdate, { isLoading }] = useUpdateUserProfileMutation();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const toast = useToast();

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(profileSchema(t, user)),
    defaultValues: {
      email: user && user.email,
      username: user && user.username,
      firstName: user && user.first_name,
      lastName: user && user.last_name,
      country: user && user.country,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, dirtyFields, isDirty },
  } = methods;

  useEffect(() => {
    reset({
      email: user && user.email,
      username: user && user.username,
      firstName: user && user.first_name,
      lastName: user && user.last_name,
      country: user && user.country,
    });
    // eslint-disable-next-line
  }, [user]);

  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    try {
      const preparedData = dirtyValues(dirtyFields, formData);
      await triggerUpdate(preparedData).unwrap();
      toast(t(tKeys.successfullyUpdatedProfile), ToastTypesEnum.success);
    } catch (e) {
      toast(t(tKeys.errorUpdatingProfile), ToastTypesEnum.error);
    }
  };

  return (
    <>
      <Flex align='center' justify='center' pb='30px'>
        <Heading
          as='h2'
          color='profile.title'
          fontSize='24px'
          fontWeight='900'
          lineHeight='1.3'
          mb='10px'
        >
          {t(tKeys.profile)}
        </Heading>
      </Flex>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormDebounceInput
            isDisabled={isLoading}
            name='email'
            placeholder={t(tKeys.email) as string}
            type='email'
          />

          <FormDebounceInput
            isDisabled={isLoading}
            name='username'
            placeholder={t(tKeys.username) as string}
            type='text'
          />

          <FormInput
            isDisabled={isLoading}
            name='firstName'
            placeholder={t(tKeys.firstName) as string}
            type='text'
          />

          <FormInput
            isDisabled={isLoading}
            name='lastName'
            placeholder={t(tKeys.lastName) as string}
            type='text'
          />

          <FormCountrySelect isDisabled={isLoading} name='country' />

          <Flex align='center' justify='center' mt='30px'>
            <Button
              isDisabled={!isValid || !isDirty}
              isLoading={isLoading}
              type='submit'
              variant='secondary'
            >
              {t(tKeys.saveChanges)}
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </>
  );
};
