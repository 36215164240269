import { Flex, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { RoutesEnum } from '@/enum/routes.enum';

import logo from './logo.svg';

export const Logo = () => {
  return (
    <>
      <Flex as={Link} to={'https://www.wtatennis.com/unlocked'}>
        <Image alt='logo' src={logo} />
      </Flex>
    </>
  );
};
