import { checkEmail } from '@/api/checkEmailExist';
import { checkNickname } from '@/api/checkNickName';

export const cacheTest = (
  asyncValidate: (val: string, prevValue?: string) => Promise<boolean>,
) => {
  let _valid = false;
  let _value = '';

  return async (value: string, prevValue?: string) => {
    if (value !== _value) {
      const response = await asyncValidate(value, prevValue);
      _value = value;
      _valid = response;
      return response;
    }
    return _valid;
  };
};

export const nicknameValidityTest = cacheTest(
  (value: string | undefined, prevValue?: string) =>
    new Promise(async (resolve) => {
      if (!value || prevValue === value) {
        resolve(true);
        return true;
      }
      const data = await checkNickname(value);
      resolve(!data.user_exists);

      return !data.user_exists;
    }),
);

export const emailValidityTest = cacheTest(
  (value: string | undefined, prevValue?: string) =>
    new Promise(async (resolve) => {
      if (!value || prevValue === value) {
        resolve(true);
        return true;
      }
      const data = await checkEmail(value);
      resolve(data.isEmailFree);

      return data.isEmailFree;
    }),
);
