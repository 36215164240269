import React, { useCallback } from 'react';

import { FormControlProps } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import CountrySelect from '@/elements/CountrySelect';

export type FormCountrySelectProps = Omit<
  FormControlProps,
  'label' | 'defaultValue'
> & {
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  defaultValue?: string;
};
const FormCountrySelect: React.FC<FormCountrySelectProps> = ({
  name,
  ...rest
}) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { field, fieldState } = useController({
    control,
    name,
  });

  const isSuccess = !errors[name]?.message && getValues(name);

  const render = useCallback(
    () => (
      <CountrySelect
        containerProps={{ mb: 'md' }}
        error={fieldState.error?.message}
        isInvalid={Boolean(fieldState.error)}
        isSuccess={isSuccess}
        {...rest}
        {...field}
      />
    ),
    [field, fieldState.error, isSuccess, rest],
  );

  return render();
};

export default FormCountrySelect;
