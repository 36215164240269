import { IS_GTAG_ENABLED, IS_XTREMEPUSH_ENABLED } from '@/utils/constants';

export const initSignupTrack = (userId: string, lang: string): void => {
  if (IS_XTREMEPUSH_ENABLED === 'true') {
    window.xtremepush('set', 'user_id', userId, function () {
      window.xtremepush('event', 'complete_registration');
      window.xtremepush('tag', 'registration_success');
      window.xtremepush('tag', 'language', lang);
      window.xtremepush('tag', 'user_lang', lang);
      window.xtremepush('user', 'update', { language: lang });
      window.xtremepush('user', 'update', { user_lang: lang });
    });
  }
};

export const initLoginTrack = (userId: string, lang: string): void => {
  if (IS_XTREMEPUSH_ENABLED === 'true') {
    window.xtremepush('tag', 'login_success');
    window.xtremepush('tag', 'language', lang);
    window.xtremepush('set', 'user_id', userId, function () {
      window.xtremepush('event', 'login');
      window.xtremepush('tag', 'user_lang', lang);
      window.xtremepush('user', 'update', { language: lang });
      window.xtremepush('user', 'update', { user_lang: lang });
    });
  }
};

export function triggerXtremepushEvent(
  eventType: string,
  eventName: string,
  eventData?: string,
): void {
  if (IS_XTREMEPUSH_ENABLED === 'true') {
    if (eventData) {
      window.xtremepush(eventType, eventName, eventData);
    } else {
      window.xtremepush(eventType, eventName);
    }
  }
}

export function triggerGTAGEvent(eventName: string): void {
  if (IS_GTAG_ENABLED === 'true') {
    window.dataLayer.push({ event: eventName });
  }
}

export const trackEvent = (event: any) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push(event);
};
