import { useCallback } from 'react';

import { useKeycloak } from '@/providers/Keycloak.provider';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useJWT } from '@/hooks/useJWT';
import { useLocalStorage } from '@/hooks/useLocalStorage';

export const useLogout = () => {
  const keycloak = useKeycloak();

  const { removeJWT } = useJWT();
  const { removeItem: removeUserID } = useLocalStorage<string>(
    StorageKeysEnum.user_id,
  );
  const { removeItem: removeKcToken } = useLocalStorage<string>(
    StorageKeysEnum.kcToken,
  );
  const { removeItem: removeKcRefreshToken } = useLocalStorage<string>(
    StorageKeysEnum.kcRefreshToken,
  );

  const logout = useCallback(async () => {
    await keycloak.logout();

    removeJWT();
    removeUserID();
    removeKcToken();
    removeKcRefreshToken();
  }, [keycloak, removeJWT, removeKcRefreshToken, removeKcToken, removeUserID]);

  return { logout };
};
