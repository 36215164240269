import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useTheme,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { enabledLanguages, tKeys } from '@/localization';

import { UserIcon } from '@/assets/icons/UserIcon';
import { ReactComponent as BackArrow } from '@/assets/icons/backArrow.svg';
import Burger from '@/assets/images/OlyBet/icon/burger.svg';
import OlybetLogo from '@/assets/images/olybet-logo.png';

import { useSidebarContext } from '@/context/sidebar.context';
import { useUserContext } from '@/context/user.context';

import LangSwitcher from '@/elements/LangSwitcher';
import { TransText } from '@/elements/TransText';

import { MediaQueriesEnum } from '@/enum/mediaQueries.enum';
import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useSettings } from '@/hooks/useSettings';

import { HIDE_PROFILE } from '@/utils/constants';

import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isDesktop } = useSettings();

  const [logoLoaded, setLogoLoaded] = useState(false);

  const isLangSwitcherEnabled = useMemo(() => enabledLanguages.length > 1, []);

  const goToHome = () => {
    navigate(RoutesEnum.HOME);
  };

  const isHeaderShown = useMemo(
    () => pathname !== RoutesEnum.SSOENTRY,
    [pathname],
  );

  const isBackButtonVisible = useMemo(
    () => pathname === RoutesEnum.PROFILE,
    [pathname],
  );

  return isDesktop ? <DesktopHeader /> : <MobileHeader />;
};

export default Header;
