import { Checkbox, FormControl, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/localization';
import { useUpdateUserDataSubscriptionMutation } from '@/services/api.service';

import { useJWT } from '@/hooks/useJWT';

import { UpdateUserDataSubscriptionBody } from '@/types/domain';

export const MarketingForm = () => {
  const { jwt } = useJWT();
  const { t } = useTranslation();

  const [updateProfile, { isLoading }] =
    useUpdateUserDataSubscriptionMutation();

  const onSubmit = async (value: boolean) => {
    const body: UpdateUserDataSubscriptionBody = {
      marketing_notifications_allow: value,
      token: jwt,
    };

    try {
      await updateProfile(body);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <FormControl>
        <Checkbox
          isDisabled={isLoading}
          onChange={(event) => onSubmit(event.target.checked)}
          pb='sm'
        >
          <Text color='signup.checkboxText' fontSize='12px' lineHeight='1.4'>
            {t(tKeys.iWouldLikeToReceiveCommunications)}
          </Text>
        </Checkbox>
      </FormControl>
    </>
  );
};
