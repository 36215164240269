import React, { useMemo } from 'react';

import {
  Box,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  Image,
} from '@chakra-ui/react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { components, ControlProps, DropdownIndicatorProps } from 'react-select';

// import { Countries } from '@/elements/CountrySelect/data';
import { FormCountrySelectProps } from '@/elements/FormCountrySelect';
import FormLabel from '@/elements/FormLabel';
import { StyledSelect } from '@/elements/StyledSelect';

import { ISelectOptionWithImg } from '@/types/domain';

import { Countries } from './counties';

export type CountrySelectProps = FormCountrySelectProps & {
  containerProps?: FormControlProps;
  error?: string;
  value?: string;
  onChange: (val: string) => void;
  isSuccess?: boolean;
};

const CountrySelectOption = ({
  innerProps,
  label,
  data,
}: {
  innerProps: any;
  label: string;
  data: ISelectOptionWithImg;
}) => (
  <Flex align='center' p='6px' {...innerProps}>
    {/* <Image alt={label} borderRadius='50%' h='25px' src={data.image} w='25px' /> */}
    <Box pl='10px'>{label}</Box>
  </Flex>
);

const CountrySelectControl = ({
  children,
  ...props
}: ControlProps<any, false> & { image?: string }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { image } = props.selectProps;

  return (
    <components.Control {...props}>
      {image && (
        <Image
          borderRadius='50%'
          height='auto'
          mr='10px'
          src={image}
          w='22px'
        />
      )}
      {children}
    </components.Control>
  );
};

const DropdownIndicator = (
  props: DropdownIndicatorProps<any, false> & { isSuccess?: boolean },
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { menuIsOpen, isSuccess } = props.selectProps;
  let arrow;
  if (menuIsOpen) {
    arrow = <IoIosArrowUp />;
  } else {
    arrow = <IoIosArrowDown />;
  }
  return (
    <components.DropdownIndicator {...props}>
      {arrow}
      {isSuccess && (
        <Box ml='5px'>
          <BsFillCheckCircleFill fill='green' size='16px' />
        </Box>
      )}
    </components.DropdownIndicator>
  );
};

const CountrySelect: React.FC<CountrySelectProps> = ({
  error,
  isInvalid,
  isDisabled,
  isSearchable = false,
  label,
  value,
  onChange,
  containerProps,
  id,
  defaultValue,
  isSuccess,
  ...rest
}) => {
  const options = useMemo(
    () => Countries.map((country) => ({ value: country, label: country })),
    [],
  );

  const selectedCountry = useMemo(
    () => options.find((x) => x.value === value),
    [options, value],
  );

  const inputId = useMemo(() => id || `${Date.now()}-${Math.random()}`, [id]);

  return (
    <FormControl {...containerProps} id={inputId} isInvalid={isInvalid}>
      {label && <FormLabel id={inputId} label={label} />}

      <StyledSelect
        components={{
          Option: CountrySelectOption,
          Control: CountrySelectControl,
          DropdownIndicator,
        }}
        defaultValue={options.find((x) => x.value === defaultValue)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        image={selectedCountry?.image}
        inputId={inputId}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isSuccess={isSuccess}
        onChange={(opt: any) => onChange(opt.value)}
        options={options}
        value={selectedCountry}
        {...rest}
      />

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default CountrySelect;
