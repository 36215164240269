export const BannedPhrase = [
  'bible basher',
  'bible beater',
  'bible thumper',
  'biblebasher',
  'bible-basher',
  'biblebeater',
  'bible-beater',
  'biblethumper',
  'bible-thumper',
  'fundie',
  'holy roller',
  'holyroller',
  'holy-roller',
  'infidel',
  'jewboy',
  'jewgirl',
  'kike',
  'kyke',
  'shylock',
  'boycottnra',
  'btsarmy',
  'buildthewall',
  'civilrights',
  'equalrights',
  'feminism',
  'feminist',
  'guncontrol',
  'guncontrolnow',
  'gundebate',
  'isis',
  'murder',
  'murdered',
  'murdering',
  'murdersuicide',
  'nazi',
  'nflboycott',
  'nra',
  'rifle',
  'sexualharrassment',
  'snowflake',
  'stateofemergency',
  'takeaknee',
  'takingaknee',
  'taxreform',
  'weremember',
  'womensmarch',
  'womensrights',
  'al qaeda',
  'assassinate',
  'assassination',
  'black lives matter',
  'blm',
  'border security',
  'boycott',
  'boycotted',
  'boycotting',
  'boycotts',
  'build the wall',
  'daesh',
  'dog fighting',
  'dogfighting',
  'dog-fighting',
  'feminism',
  'feminist',
  'genocide',
  'genocides',
  'gun control',
  'gun man',
  'guncontrol',
  'gun-control',
  'gunman',
  'hitler',
  'illegal alien',
  'immigrants',
  'immigration',
  'isil',
  'isis',
  'legal alien',
  'massacre',
  'massacred',
  'massacres',
  'migrant',
  'migrants',
  'misogynism',
  'misogynist',
  'misogyny',
  'molest',
  'molestation',
  'molested',
  'murder-suicide',
  'nazis',
  'nra',
  'pedophile',
  'pedophilia',
  'pervert',
  'perverted',
  'petaphile',
  'plane crash',
  'planecrash',
  'protest',
  'protesting',
  'protests',
  'rape',
  'raped',
  'raper',
  'rapist',
  'refugee',
  'refugees',
  'rip',
  'sexism',
  'sexist',
  'shooter',
  'shooters',
  'shooting',
  'slavery',
  'slaves',
  'suicide',
  'syria',
  'tax reform',
  'terror',
  'terrorism',
  'terrorist',
  'terrors',
  'weinstein',
  'welfare',
  'white lives matter',
  'womens march',
  "women's march",
  'equality',
  'abortion',
  'abort baby',
  'abort child',
  'abort children',
  'abort boy',
  'abort girl',
  'pro abortion',
  'anti abortion',
  'pro-abortion',
  'anti-abortion',
  '420',
  '4/20',
  '420',
  'amphetamine',
  'bath salts',
  'butane',
  'cocaine',
  'crystal meth',
  'drop acid',
  'dropping acid',
  'ecstasy',
  'flakka',
  'ghb',
  'heroin',
  'ketamine',
  'khat',
  'lsd',
  'meth',
  'meth head',
  'methadone',
  'methamphetamine',
  'methhead',
  'opioids',
  'rape drug',
  'scopolamine',
  'bum bandit',
  'bumbandit',
  'bum-bandit',
  'butch',
  'butchbroad',
  'butch-broad',
  'butt pirate',
  'buttpirate',
  'butt-pirate',
  'carpet muncher',
  'carpetmuncher',
  'carpet-muncher',
  'celesbian',
  'chi chi man',
  'chi-chi man',
  'cock jockey',
  'cockjockey',
  'cock-jockey',
  'cuntboy',
  'dickgirl',
  'dyke',
  'fag',
  'faggot',
  'fairy',
  'fruit cake',
  'fruitcake',
  'fruit-cake',
  'fudge packer',
  'fudgepacker',
  'fudge-packer',
  'futanari',
  'gay',
  'gays',
  'homo',
  'homosexual',
  'knob jockey',
  'knobjockey',
  'knob-jockey',
  'lesbian',
  'lesbians',
  'limpwrist',
  'limp-wrist',
  'limpwristed',
  'limp-wristed',
  'muff diver',
  'muffdiver',
  'muff-diver',
  'pansexual',
  'pillow biter',
  'pillowbiter',
  'pillow-biter',
  'poof',
  'poofer',
  'shemale',
  'soy boy',
  'soyboy',
  'soy-boy',
  'todger dodger',
  'todgerdodger',
  'todger-dodger',
  'tranny',
  'trannys',
  'trans',
  'transexual',
  'transexuals',
  'turd burglar',
  'turdburglar',
  'turd-burglar',
  'twink',
  'androgynous',
  'bean flicker',
  'beanflicker',
  'bean-flicker',
  'bisexual',
  'bone smuggler',
  'bonesmuggler',
  'flamer',
  'bone-smuggler',
  'arse',
  'arse hole',
  'arsehole',
  'arse-hole',
  'ass',
  'ass bag',
  'ass clown',
  'ass face',
  'ass hat',
  'ass hole',
  'ass wad',
  'ass wipe',
  'assbag',
  'ass-bag',
  'assclown',
  'ass-clown',
  'assface',
  'ass-face',
  'asshat',
  'ass-hat',
  'asshole',
  'ass-hole',
  'asswad',
  'ass-wad',
  'asswipe',
  'ass-wipe',
  'bastard',
  'crap',
  'crapped',
  'crapper',
  'dammit',
  'damn',
  'dumb ass',
  'dumb asses',
  'dumbass',
  'dumb-ass',
  'dumbasses',
  'dumb-asses',
  'lame ass',
  'lameass',
  'lame-ass',
  'lard ass',
  'lardass',
  'lard-ass',
  'piss',
  'pissed',
  'pisser',
  'pissing',
  'retard',
  'retarded',
  'snowflake',
  'scumbag',
  '💩',
  '💨',
  '45thpresident',
  'altright',
  'bloomberg',
  'capitolhill',
  'cbs',
  'cbsnews',
  'chicagotimes',
  'cnbc',
  'cnn',
  'cpac',
  'fakenews',
  'foxnews',
  'government',
  'hesmypresident',
  'impeachtrump',
  'latimes',
  'maga',
  'marcorubio',
  'msnbc',
  'mypresident',
  'nbc',
  'nbcnews',
  'nevermypresident',
  'newyorkpost',
  'newyorktimes',
  'notmypresident',
  'nytimes',
  'obama',
  'paulmanafort',
  'presidentdonald',
  'presidentdonaldtrump',
  'presidenttrump',
  'primeminister',
  'rickgates',
  'tedcruz',
  'thewhitehouse',
  'trump',
  'usatoday',
  'wallstreetjournal',
  'washingtonpost',
  'whitehouse',
  'yourpresident',
  'abc',
  'abc news',
  'abcnews',
  'alt right',
  'alt+right',
  'altright',
  'alt-right',
  'barack obama',
  'barackobama',
  'bloomberg',
  'breitbart',
  'cap hill',
  'capitol hill',
  'cbs',
  'cbs news',
  'cbsnews',
  'chicago times',
  'civil suit',
  'civil-suit',
  'cnbc',
  'cnn',
  'common law',
  'confederates',
  'congress',
  'congressional',
  'conservative',
  'cpac',
  'democracy',
  'democrat',
  'election',
  'fake news',
  'fakenews',
  'fake-news',
  'federal',
  'flotus',
  'fox news',
  'foxnews',
  'gop',
  'government',
  'grand old party',
  'gwot',
  'hillary clinton',
  'hillaryclinton',
  'house of representatives',
  'ivanka trump',
  'james comey',
  'la times',
  'latimes',
  'left wing',
  'leftwing',
  'left-wing',
  'legislation',
  'legislative',
  'liberal',
  'make america great again',
  'marco rubio',
  'marcorubio',
  'melania',
  'msnbc',
  'nationalism',
  'nationalist',
  'nato',
  'nbc',
  'new york post',
  'new york times',
  'newsday',
  'newyorkpost',
  'ny times',
  'nytimes',
  'obama',
  'obamacare',
  'paul manafort',
  'political',
  'politically correct',
  'politicians',
  'potus',
  'president',
  'presidential',
  'putin',
  'realdonaldtrump',
  'republican',
  'rick gates',
  'right wing',
  'sanction',
  'sanctions',
  'senate',
  'separatists',
  'stalin',
  'state department',
  'steve bannon',
  'surpreme court',
  'ted cruz',
  'tedcruz',
  'trump',
  'usa today',
  'usatoday',
  'wall street',
  'wallstreetjournal',
  'washington post',
  'washtingtonpost',
  'white house',
  'whitehouse',
  'white-house',
  'socialist',
  'fascist',
  'propaganda',
  'marxism',
  'marxist',
  'commie',
  'libtard',
  '🇺🇸+👎',
  '🇺🇸+🖕',
  '🇺🇸+🤮',
  '🇺🇸+💀',
  '🇺🇸+☠',
  '🇺🇸+🤡',
  '🇺🇸+🙅',
  '🇺🇸+🙅',
  '🇺🇸+🙅',
  '🇺🇸+🚫',
  '🇺🇸+🗑',
  '🇺🇸+🤢',
  '🇺🇸+❌',
  '🇺🇸+🔪',
  '🇺🇸+🔫',
  '🇺🇸+⚰',
  '🇺🇸+⬇',
  'Socialist',
  'Fascist',
  'Propaganda',
  'Marxism',
  'Marxist',
  'Commie',
  'Libtard',
  'Kanye 2020',
  'right-wing',
  'rightwing',
  'kanye2020',
  'Joe Biden',
  'Biden',
  'joebiden',
  'biden',
  'biden2020',
  'joebiden2020',
  'Kamala Harris',
  'Kamala  ',
  'Harris',
  'kamalaharris',
  'harris',
  'bidenharris',
  'bidenharris2020',
  'Biden/Harris',
  'BidenHarris2020',
  'Antifa',
  'Libtarded',
  'Biden-Harris',
  'harrisbiden2020',
  'vote red',
  'vote blue',
  'votered',
  'voteblue',
  'radical left',
  'radical right',
  'commies',
  'trump2020',
  'trump 2020',
  'abbo',
  'abbos',
  'abo',
  'abos',
  'ape',
  'beaner',
  'beaners',
  'camel jockey',
  'cameljockey',
  'camel-jockey',
  'china men',
  'chinamen',
  'ching chong',
  'chingchong',
  'ching-chong',
  'cholo',
  'coon',
  'coon ass',
  'coonass',
  'coon-ass',
  'cracker',
  'curry muncher',
  'currymuncher',
  'curry-muncher',
  'dago',
  'dagos',
  'geebag',
  'gook',
  'gooks',
  'greaseball',
  'gringo',
  'gringos',
  'halfbreed',
  'half-breed',
  'heeb',
  'nigga',
  'paleface',
  'peckerwood',
  'pikey',
  'polack',
  'jigaboo',
  'jiggaboo',
  'jungle bunny',
  'junglebunny',
  'jungle-bunny',
  'kaffir',
  'micks',
  'negro',
  'nigger',
  'nigger rigging',
  'niggerrigging',
  'nigger-rigging',
  'niggers',
  'niglet',
  'niglets',
  'nignog',
  'nig-nog',
  'nignogs',
  'nig-nogs',
  'nip',
  'orientals',
  'polak',
  'polock',
  'porch monkey',
  'porchmonkey',
  'porch-monkey',
  'racialism',
  'racialists',
  'racism',
  'racist',
  'racists',
  'rag head',
  'raghead',
  'rag-head',
  'red neck',
  'redneck',
  'red-neck',
  'rednecks',
  'ruski',
  'sandnigga',
  'sandnigger',
  'shylock',
  'skinhead',
  'skinheads',
  'slant eye',
  'slanteye',
  'slant-eye',
  'slavery',
  'spearchucker',
  'spic',
  'spick',
  'spics',
  'spig',
  'spigotty',
  'spook',
  'spooks',
  'towel head',
  'towelhead',
  'towel-head',
  'uncle tom',
  'uncletom',
  'uncle-tom',
  'waap',
  'wegro',
  'wetback',
  'wetbacks',
  'whigger',
  'white power',
  'whitepower',
  'white-power',
  'whitey',
  'wigga',
  'wigger',
  'zipperhead',
  'Snowflake',
  'Nigga',
  'Nigar',
  'bitch',
  'bitch ass',
  'bitchass',
  'bitch-ass',
  'bitches',
  'bitchy',
  'bull shit',
  'bullshit',
  'bull-shit',
  'cluster fuck',
  'clusterfuck',
  'cluster-fuck',
  'fuck',
  'fuck bag',
  'fuck boy',
  'fuck face',
  'fuck head',
  'fuck hole',
  'fuck off',
  'fuck tard',
  'fuck up',
  'fuck wad',
  'fuckbag',
  'fuck-bag',
  'fuckboy',
  'fuck-boy',
  'fucked',
  'fucker',
  'fuckface',
  'fuck-face',
  'fuckhead',
  'fuck-head',
  'fuckhole',
  'fuck-hole',
  'fuckin',
  "fuckin'",
  'fucking',
  'fuckoff',
  'fuck-off',
  'fucks',
  'fucktard',
  'fuck-tard',
  'fuckup',
  'fuck-up',
  'fuckwad',
  'fuck-wad',
  'mother fucker',
  'motherfucker',
  'mother-fucker',
  'mutha fucka',
  'muthafucka',
  'mutha-fucka',
  'shit',
  'shit head',
  'shithead',
  'shit-head',
  'shits',
  'shitted',
  'shitter',
  'shitting',
  'shitbag',
  '🤬',
  '🖕',
  '🖕🏻',
  '🖕🏼',
  '🖕🏽',
  '🖕🏾',
  '🖕🏿',
  'Goddamn',
  'Horsecock',
  'Hellhole',
  'Shitty',
  'SOB',
  'STFU',
  'GTFO',
  'anal',
  'bigboobs',
  'blowjob',
  'boner',
  'booty',
  'brazzers',
  'camgirl',
  'cammodel',
  'chaturbate',
  'cock',
  'cocktribute',
  'cum',
  'cumming',
  'cumshot',
  'cumslut',
  'dreamgirl',
  'femdom',
  'financialdom',
  'financialdominatrix',
  'findom',
  'gangbang',
  'gayporn',
  'hardcock',
  'horny',
  'hornydm',
  'hot',
  'kikgirls',
  'kinky',
  'livecam',
  'naughty',
  'nsfw',
  'nude',
  'oral',
  'oralsex',
  'orgasm',
  'pinktaco',
  'porn',
  'porno',
  'pornstar',
  'pussy',
  'queerporn',
  'sex',
  'sexslave',
  'sexually',
  'sexy',
  'squirt',
  'tit',
  'tittyfuck',
  'webcamsex',
  'wet',
  'xxx',
  '3some',
  'anal',
  'anus',
  'arsehole',
  'ass bag',
  'ass bandit',
  'ass banger',
  'ass bite',
  'ass clown',
  'ass cock',
  'ass cracker',
  'ass face',
  'ass fuck',
  'ass fucker',
  'ass goblin',
  'ass hole',
  'ass holes',
  'ass hopper',
  'ass jabber',
  'ass jacker',
  'ass lick',
  'ass licker',
  'ass monkey',
  'ass munch',
  'ass muncher',
  'ass pirate',
  'ass sucker',
  'ass to mouth',
  'ass wad',
  'ass wipe',
  'assbag',
  'ass-bag',
  'assbandit',
  'ass-bandit',
  'assbanger',
  'ass-banger',
  'assbite',
  'ass-bite',
  'assclown',
  'ass-clown',
  'asscock',
  'ass-cock',
  'asscracker',
  'ass-cracker',
  'assface',
  'ass-face',
  'assfuck',
  'ass-fuck',
  'assfucker',
  'ass-fucker',
  'assgoblin',
  'ass-goblin',
  'ass-hat',
  'asshole',
  'ass-hole',
  'assholes',
  'ass-holes',
  'asshopper',
  'ass-hopper',
  'assjabber',
  'ass-jabber',
  'assjacker',
  'ass-jacker',
  'asslick',
  'ass-lick',
  'asslicker',
  'ass-licker',
  'assmonkey',
  'ass-monkey',
  'assmunch',
  'ass-munch',
  'assmuncher',
  'ass-muncher',
  'asspirate',
  'ass-pirate',
  'asssucker',
  'ass-sucker',
  'ass-to-mouth',
  'asswad',
  'ass-wad',
  'asswipe',
  'ass-wipe',
  'baby dick',
  'babydick',
  'baby-dick',
  'beef curtain',
  'beef curtains',
  'beefcurtain',
  'beef-curtain',
  'beefcurtains',
  'beef-curtains',
  'big black dick',
  'bitch tits',
  'bitchtits',
  'bitch-tits',
  'black dick',
  'blow job',
  'blowjob',
  'blow-job',
  'blowjobs',
  'blumpkin',
  'blump-kin',
  'bollocks',
  'bollox',
  'bondage',
  'boner',
  'boners',
  'boob',
  'boobs',
  'boxmuncher',
  'brazzers',
  'brotherfucker',
  'bust load',
  'butt fucka',
  'butt fucker',
  'butt plug',
  'buttfucka',
  'butt-fucka',
  'buttfucker',
  'butt-fucker',
  'butt-pirate',
  'buttplug',
  'butt-plug',
  'camel toe',
  'cameltoe',
  'camel-toe',
  'carpet muncher',
  'carpetmuncher',
  'carpet-muncher',
  'child porn',
  'child-porn',
  'choad',
  'chode',
  'choke fuck',
  'choke fucked',
  'choke-fuck',
  'cleveland steamer',
  'clit',
  'cluster fuck',
  'clusterfuck',
  'cluster-fuck',
  'cock',
  'cock face',
  'cock fucker',
  'cock head',
  'cock jockey',
  'cock knocker',
  'cock master',
  'cock mongler',
  'cock monkey',
  'cock smoker',
  'cock sucker',
  'cockface',
  'cock-face',
  'cockfucker',
  'cock-fucker',
  'cockhead',
  'cock-head',
  'cockjockey',
  'cock-jockey',
  'cockknocker',
  'cock-knocker',
  'cockmaster',
  'cock-master',
  'cockmongler',
  'cock-mongler',
  'cockmonkey',
  'cock-monkey',
  'cocksmoker',
  'cock-smoker',
  'cocksucker',
  'cock-sucker',
  'coin slot',
  'coinslot',
  'coin-slot',
  'cooter',
  'cum',
  'cum dumpster',
  'cum guzzler',
  'cum jockey',
  'cum shot',
  'cum slut',
  'cumdumpster',
  'cum-dumpster',
  'cumguzzler',
  'cum-guzzler',
  'cumjockey',
  'cum-jockey',
  'cumming',
  'cumshot',
  'cum-shot',
  'cumslut',
  'cum-slut',
  'cunnilingus',
  'cunt',
  'cunt ass',
  'cunt face',
  'cunt hole',
  'cunt rag',
  'cuntass',
  'cunt-ass',
  'cuntface',
  'cunt-face',
  'cunthole',
  'cunt-hole',
  'cuntrag',
  'cunt-rag',
  'cunts',
  'dick',
  'dick head',
  'dickhead',
  'dick-head',
  'dicks',
  'dildo',
  'dildos',
  'dirty sanchez',
  'dirtysanchez',
  'dominatrix',
  'donk',
  'double penetration',
  'double-penetration',
  'douche',
  'douche bag',
  'douchebag',
  'douche-bag',
  'douchebags',
  'douches',
  'eat dick',
  'ejaculate',
  'ejaculated',
  'erotic',
  'erotica',
  'fap',
  'fapped',
  'fapping',
  'felch',
  'felcher',
  'felches',
  'fetish',
  'fist fuck',
  'fistfuck',
  'fist-fuck',
  'fisting',
  'foot fetish',
  'foot-fetish',
  'fuck hole',
  'fuck toy',
  'fuckhole',
  'fuck-hole',
  'fucktoy',
  'fuck-toy',
  'fudge packer',
  'fudgepacker',
  'fudge-packer',
  'gang bang',
  'gang banged',
  'gangbang',
  'gang-bang',
  'gangbanger',
  'gangbangers',
  'gangbangs',
  'gooch',
  'grundle',
  'hand job',
  'handjob',
  'hand-job',
  'hardcore sex',
  'hardcore-sex',
  'hiv',
  'hoe',
  'hoes',
  'horny',
  'jack off',
  'jackoff',
  'jack-off',
  'jerk off',
  'jerkoff',
  'jerk-off',
  'jizz',
  'jizzing',
  'kink',
  'kinky',
  'kooch',
  'kunt',
  'lesbian',
  'lick box',
  'lick-box',
  'masterbate',
  'masterbated',
  'masterbator',
  'meat curtains',
  'meat flap',
  'meatcurtains',
  'meat-curtains',
  'meatflap',
  'meat-flap',
  'milf',
  'm-i-l-f',
  'milfs',
  'moose knuckle',
  'mooseknuckle',
  'moose-knuckle',
  'munch box',
  'munchbox',
  'munch-box',
  'nude',
  'nudes',
  'nudez',
  'nut sack',
  'nutsack',
  'nut-sack',
  'orgasm',
  'orgasmed',
  'orgasms',
  'pedophile',
  'pedophiles',
  'pedophilia',
  'penis',
  'penises',
  'petaphiles',
  'pink taco',
  'pinktaco',
  'pink-taco',
  'playboy',
  'play-boy',
  'poontang',
  'porn',
  'porn hub',
  'porn star',
  'pornhub',
  'porn-hub',
  'porno',
  'pornography',
  'pornos',
  'porns',
  'pornstar',
  'porn-star',
  'prick',
  'prostitute',
  'prostitutes',
  'prostitution',
  'pussy',
  'pussy fart',
  'pussyfart',
  'pussy-fart',
  'queef',
  'queefed',
  'rape',
  'raped',
  'raper',
  'rapes',
  'rapist',
  'red tube',
  'redtube',
  'red-tube',
  'renob',
  'rim job',
  'rimjob',
  'rim-job',
  'rusty trombone',
  'rusty-trombone',
  'schlong',
  'scrote',
  'scrotum',
  'sex',
  'sext',
  'sexting',
  'sexually',
  'sexy',
  'skank',
  'skanks',
  'skeet',
  'skull fuck',
  'skullfuck',
  'skull-fuck',
  'slong',
  'sperm',
  'spermy',
  'splooge',
  'splooged',
  'std',
  'stds',
  'taint',
  'thicc',
  'thot',
  'three some',
  'threesome',
  'three-some',
  'tit',
  'tits',
  'titties',
  'titty',
  'twat',
  'vag',
  'vagina',
  'vaginas',
  'vajayjay',
  'vjayjay',
  'wanker',
  'whore',
  'whores',
  'schlong',
  'coochie',
  'orifices',
  'erect',
  'cuck',
  'Shlong',
  'Coochie',
  'Cooch',
  'Orifices',
  'Erect',
  'bitcoin',
  'btc',
  'eth',
  'xrp',
  'tether',
  'USDT',
  'bitcoin cash',
  'BCH',
  'litecoin',
  'LTC',
  'cardano',
  'ADA',
  'chainlink',
  'binance coin',
  'BNB',
  'eos',
  'tezos',
  'XTZ',
  'etherium',
  'we should collaborate',
  'link in bio',
  'dm me',
  'dm our',
  'dm my profile',
  'dm my insta',
  'dm my @',
  'dm my website',
  'dm my site',
  'direct message me',
  'direct message my profile',
  'direct message my insta',
  'direct message our',
  'check my',
  'check my link',
  'check my insta',
  'check my @',
  'check my profile',
  'check out my',
  'check out my link',
  'check out my insta',
  'check out @',
  'check out my profile',
  'hit up my',
  'hit up my link',
  'hit up my profile',
  'hit up our profile',
  'visit our page',
  'visit our profile',
  'visit our @',
  'visit our insta',
  'follow me',
  'follow my @',
  'follow my profile',
  'follow my insta',
  'follow @',
  'lets collab',
  'lets collaborate',
  'we should collab',
  '👎',
  '👎🏻',
  '👎🏼',
  '👎🏽',
  '👎🏾',
  '👎🏿',
  'bill paid',
  'Follow Here',
  'Link Here',
  'Live Link',
  'Link HD',
  'DM',
  'watch my videos',
  'like this comment',
  'bills paid',
  'paid bill',
  'pml',
  'george floyd',
  'georgefloyd',
  'columbine',
  'covington',
  'parkland',
  'sandyhook',
  'columbine',
  'covington',
  'parkland',
  'sandy+hook',
  'STEMshooting',
  'STEMSchoolHighlandsRanch',
  'HighlandsRanch',
  'STEM School',
  'Kendrick Castillo',
  'sandy hook',
  'sandyhook',
  'zika',
  'lyssavirus',
  'norovirus',
  'alphavirus',
  'machupo',
  'Coronavirus',
  'Coronavirusoutbreak',
  'covid',
  'c19',
  'covid-19',
  'corona',
  'Caronavirus',
  'Wuhan',
  'CDC',
  'centers for disease control',
  'center for disease control',
  'coca',
  'Clinician Outreach and Communication Activity',
  'novel',
  'novel strain',
  'sars',
  'measles',
  'rotavirus',
  'H1n1',
  'mononucleosis',
  'ebola',
  'dengue fever',
  'rotavirus',
  'influenza b',
  'adenovirus',
  'west nile virus',
  'epstein barr',
  'epstein barr virus',
  'zika virus',
  'herpangina',
  'viral infection',
  'ebola virus',
  'dengue',
  'swine flu',
  'cytomegalovirus',
  'h1n1',
  'eee virus',
  'sars virus',
  'coxsackievirus',
  'coxsackie',
  'zika',
  'parainfluenza',
  'bird flu',
  'cmv virus',
  'mumps virus',
  'dengue virus',
  'triple e virus',
  'infectious mononucleosis',
  'west nile',
  'powassan virus',
  'zika baby',
  'zoster',
  'japanese encephalitis',
  'nipah virus',
  'mosquito diseases',
  'coronita',
  'hand foot and mouth virus',
  'poliovirus',
  'hemorrhagic fever',
  'rubella virus',
  'rabies virus',
  'new virus',
  'ebv virus',
  'jc virus',
  'mosquito virus',
  'avian flu',
  'viral fever',
  'virion',
  'viral encephalitis',
  'cdc zika',
  'shingles virus',
  'roseola virus',
  'measles virus',
  'htlv',
  'capsid',
  'norwalk virus',
  'zika virus baby',
  'lassa fever',
  'spanish influenza',
  'casa corona',
  'norovirus treatment',
  'adenovirus infection',
  'bk virus',
  'monkeypox',
  'eee mosquito',
  'coronarita',
  'h5n1',
  'parvovirus in humans',
  'chikungunya virus',
  'ebv infection',
  'mouth disease',
  'astrovirus',
  'mers virus',
  'variola',
  'sars disease',
  'filovirus',
  'airborne diseases',
  'chickenpox virus',
  'rubeola virus',
  'hand foot mouth virus',
  '1918 flu',
  'epstein barr syndrome',
  'tobacco mosaic virus',
  'pox virus',
  'ebola chan',
  'flavivirus',
  'sapovirus',
  'rsv contagious',
  'viral infection treatment',
  'viral diseases',
  'paramyxovirus',
  'ebola zaire',
  'eee mosquito virus',
  'picornavirus',
  'influenza type b',
  'ebola 2019',
  'ebola treatment',
  'mosquito borne diseases',
  'parainfluenza virus',
  'vesicular stomatitis',
  'hand foot mouth contagious',
  'rna virus',
  'provirus',
  'sras',
  'avian influenza',
  'h7n9',
  'variola virus',
  'dengue fever treatment',
  'lassa virus',
  'smallpox virus',
  'nipah',
  'west nile fever',
  'virus cell',
  'rhino enterovirus',
  'h1n1 virus',
  'flesh eating virus',
  'ebola river',
  'corona price',
  'paraflu',
  'types of viral infection',
  'disseminated shingles',
  'pml virus',
  'echovirus',
  'dengue treatment',
  'h1n1 swine flu',
  'norovirus contagious',
  'mosquito borne eee virus',
  'subacute sclerosing panencephalitis',
  'zika mosquito',
  'viral skin infections',
  'airborne virus',
  'h1n1 flu',
  'dengue hemorrhagic fever',
  'epstein barr virus treatment',
  'reovirus',
  'corona shirt',
  'cytomegalovirus infection',
  'viral hemorrhagic fever',
  'cullen virus',
  'polyomavirus',
  'zoster virus',
  'coxsackie b virus',
  'rhabdovirus',
  'enveloped virus',
  'herpangina in adults',
  'parainfluenza 3',
  'epstein barr virus infection',
  'chronic epstein barr virus',
  'ebola virus disease',
  'viral shedding',
  'enterovirus d68',
  'neurotropic',
  'latent infection',
  'viral replication',
  'bird flu virus',
  'blue tongue disease',
  'herpangina virus',
  'chikungunya virus infection',
  'coxsackievirus in adults',
  'corona guava',
  'triple eee',
  'corona cans',
  'powassan',
  'influenza b virus',
  'zoster sine herpete',
  'ebola disease',
  'vaccinia virus',
  'yellow fever mosquito',
  'hendra virus',
  'common viruses',
  'disseminated zoster',
  'new corona',
  'nile virus',
  'zika fever',
  'zika virus and pregnancy',
  'lyssavirus',
  'norovirus infection',
  'alphavirus',
  'human herpesvirus 6',
  'virus names',
  'influenza pandemic',
  'machupo virus',
  'Covid19',
  'Coronavirus',
  '2019Ncov',
  'Ncov2019',
  'Wuhan',
  'Sarscov2',
  'Coronavirusoutbreak',
  'Covid_19',
  'Covid2019',
  'Covid',
  'outbreak',
  '2019-nCoV',
  'Outbreaks',
  'Diseases',
  'The World Health Organization',
  'Public Health England',
  'NIAID',
  'Gilead Sciences',
  'Thomson Reuters',
  'US Department of Health and Human Services',
  'Biogen',
  'west nile',
  'epstein barr',
  'zika',
  'ebola',
  'sars',
  'coxsackievirus',
  'mumps',
  'dengue',
  'triple e',
  'powassan',
  'nipah',
  'hand foot and mouth',
  'poliovirus',
  'rubella',
  'rabies',
  'shingles',
  'roseola',
  'measles',
  'norwalk',
  'zika',
  'norovirus',
  'adenovirus',
  'parvovirus',
  'chikungunya',
  'astrovirus',
  'mers',
  'filovirus',
  'chickenpox',
  'rubeola',
  'hand foot mouth',
  'tobacco mosaic',
  'pox',
  'flavivirus',
  'sapovirus',
  'paramyxovirus',
  'eee mosquito',
  'picornavirus',
  'parainfluenza',
  'rna',
  'provirus',
  'variola',
  'lassa',
  'smallpox',
  'rhino',
  'h1n1',
  'flesh eating',
  'infection',
  'contagious',
  'echovirus',
  'norovirus',
  'airborne',
  'epstein',
  'reovirus',
  'cytomegalovirus',
  'cullen',
  'polyomavirus',
  'zoster',
  'coxsackie',
  'rhabdovirus',
  'enveloped',
  'epstein',
  'chronic',
  'ebola',
  'enterovirus',
  'bird flu',
  'herpangina',
  'chikungunya',
  'Jacob Blake',
  'Kenosha',
  'Rusten Sheskey',
  'JacobBlake',
  'Kenosha',
  'Kyle Rittenhouse',
  'Rittenhouse',
  'kylerittenhouse',
  'justiceforjacob',
  'Wildcat strike',
  'i like something',
  'long, hard and durable',
  'riot',
  'trigger',
  'triggered',
  'white privilege ',
  'politics',
  'virtue signal',
  'i need something ',
  'ᵒᵒᵒᵒᵒᵐᵍ ⁱ ʷᵃⁿᵗ ᵃ ᵍᵉⁿᵗᵉˡᵐᵃⁿ',
  'follow for ',
  'ˡⁱᵏᵉ ⁱᶠ ʸᵒᵘ ᵃʳᵉ ᵐᵒʳᵉ ¹⁶ ᶜᵐ',
  'i really like cucumber.! do you like him too? 😝😜',
  'free nhl bets',
  '@christensen_james_fxt ',
  'do not tap on my username',
  'who can impregnate me. because my man is weak',
  'warning!!!!!! do not view my story!!',
  'sim league',
  '@russell_stock_expert',
  'ⁱ ᵃᵐ ᵗʰⁱʳˢᵗʸ, ʷᵃⁱᵗⁱⁿᵍ ᶠᵒʳ ʸᵒᵘ',
  "i am everyone's dream girl !!!",
  'i think dad is always stimulating🤭',
  '@tastrades',
  'feels kinda sad today😔',
  '@trade_with_orsborne',
  '@earn_with_dylanfx',
  '@earn_with_emily_fxt',
  '@earn_profits_with_john_eric',
  'new page, give us a look!!',
  '@jennifer_expert_trader9',
  'guess what size is my bra? ',
  'i’m online❤',
  '𝑪𝒂𝒓𝒕𝒐𝒐𝒏 𝒈𝒓𝒂𝒑𝒉𝒊𝒄𝒔',
  '𝐛𝐢𝐧𝐚𝐫𝐲/𝐟𝐨𝐫𝐞𝐱 𝐭𝐫𝐚𝐝𝐢𝐧𝐠',
  'live now 🔴►',
  '@a.bwagers',
  '@elizabet.morrison01',
  '@trade_with_olivia_lindsay',
  'kys',
  'snow flake',
  'straight pride',
  'dipshit',
  'kill yourself',
  'sexi',
  'spiÃ§',
  'sodomy',
  'straight people pride',
  'sodomite',
  'original memes',
  'dm for ',
  'dm for',
  'bye nhl',
  'amerikkka',
  'mob',
  'nut jobs',
  'grow balls',
  'darkies',
  'pedo',
  'nut job',
  'grew balls',
  'had balls',
  'klan',
  'fucknut',
  'done with nhl ',
  'felon',
  'not watching nhl',
  'pansy',
  'no more nhl',
  'asshat',
  'dbag',
  'domestic terrorist',
  'gtfo',
  'domestic terrorists',
  'cult',
  'pussys',
  'jackass',
  'domestic terrorism',
  'kkk',
  "you're a jew",
  'fkn',
  'trump 2020ðŸ‡ºðŸ‡¸',
  'ass hat',
  'fuk',
  'blm is racist',
  'all lives matter',
  'dumbass',
  'dumbasses',
  'pussies',
  'dumbfuckistan',
  'fuking',
  'd-bag',
  'fake agenda',
  'politics out of hockey',
  'rate me',
  'watch my ',
  'private video',
  'coke head',
  'private videos',
  'bills covered',
  'live streams',
  'link here',
  '@micheal_richardsfx',
  '@crypto_wilson_trader_fx',
  'scammed',
  '@investor_christopher_crypto',
  'watch live====>>>',
  'fan page',
  '@karenlisa_forex_trade',
  '@alice_klein_cryptos',
  'rate my foto',
  'who needs his/her bills paid',
  'weskateformarxism',
  'forex trading',
  '@zarina_fx',
  'live stream',
  'dm',
  'copy and paste',
  '@educator_nancyfx',
  'scammed,',
  'link here: ',
  'on my page',
  'follow my tiktok ',
  'birthcertificatefraud',
  'i invested',
  'look at my story',
  'link hd',
  'live link:>>>',
  'sub to me',
  '@marvntrades',
  'black lives donâ€™t matter',
  'mike weirsky,',
  'trust worthy account manager',
  'testimony',
  'candid pics',
  'mr gary',
  '@pamela_binary_expert_ ',
  'end my order',
  'share everywhere',
  '@earn_with_lucy_fx',
  'forex',
  'worldwidewarning',
  'live link',
  'rate my new photos',
  'get me a profit',
  ' make all sort of artwork',
  'i sell',
  'financial support',
  'follow here',
  'hi.rate me in my profile',
  '@officialconsultant_debbie',
  'watch !!!',
  'rate me in my profile',
  '@gary___tomson_',
  'send me message',
  'pay my bills',
  'follow to join',
  'waiting in profile',
  '@jody_steiner_fx1',
  'dont look at my story',
  'pay my bill',
  'send a message',
  'contact me',
  'live here',
  'sportsurge.net',
  'link here:',
  'fix your page',
  '.rate me in my profile',
  'rate my new photos,',
  'rate my new photo',
  'please watch and share',
  'send my order',
  'link in our bio',
  'watch my video',
  'i make all sort of artwork',
  'hoax',
  'live on',
  'www.shazerwan.com',
  'covid cup',
  'https://is.gd/banmvk',
  'watch live',
  'binary ',
  'debt free',
  'in my story',
  'live links',
  'binary trading',
  '@theclaire_financial_freedom',
  'criminal',
  'martyr',
  'political gain',
  'rig ',
  'politics out',
  '🏳️',
  'get woke go broke',
  'caved',
  'destroy america',
  'go broke',
  '𝖽𝖺𝗂𝗅𝗒 𝗍𝗋𝖺𝖽𝗂𝗇𝗀 𝗌𝗂𝗀𝗇𝖺𝗅𝗌 ',
  'rigged',
  'see my bio',
  '𝐰𝐢𝐭𝐡𝐝𝐫𝐚𝐰𝐚𝐥',
  '𝐦𝐚𝐝𝐞 𝐩𝐫𝐨𝐟𝐢𝐭𝐬',
  '@dr_isespell💜',
  'paid to play',
  '𝒆 𝒛 𝒃 𝒊 𝒈 𝒑 𝒂 𝒚 .𝒄 𝒐 𝒎',
  '✅𝕃𝕀𝕍𝔼✅👉',
  '【🔴watch】',
  'would you help me take off my clothes🥰',
  'hate group',
  'oppressor',
  '🆚',
  '🔴👉',
  'radical leftist',
  'sports without politics',
  'support criminals',
  'oppression',
  'join me',
  'constatly bother me',
  'just play hockey',
  'hate blacks',
  'oppressed',
  'david scott',
  "i'm bored",
  'f4f',
  'im bored',
  'virtue signaling',
  'cowards',
  'sheep',
  'take a knee',
  'entertainment in my profile',
  'telegram',
  'looting',
  'stick to hockey',
  'no one cares',
  'rioting',
  'chinese sweatshops',
  'gunpoint',
  'fentanyl',
  'china',
  'woke',
  'are there any people interested?',
  'kaepernick',
  'false movement',
  'shitstains',
  'getwokegobroke',
  'shut up and play',
  'daddy, l can sh0w you everything y0u want)',
  'i just took a shower',
  'do your job',
  'not your platform',
  'underwear',
  'vote on my story',
  'stick to your job',
  'thugs',
  'thug',
  'criminals',
  '𝗜 𝘄𝗮𝗻𝘁 𝘀𝗼𝗺𝗲𝘁𝗵𝗶𝗻𝗴 𝗹𝗼𝗻𝗴 𝗮𝗻𝗱 𝘁𝗵𝗿𝗶𝗰𝗸, 𝗱𝗼 𝘆𝗼𝘂 𝗵𝗮𝘃𝗲 𝗶𝘁',
  'hypocrite',
  'hate crime',
  'drug addicts',
  'piss off',
  'farce',
  'kaep',
  'they say my beats hit hard',
  'dumbo',
  '@lina.colby__',
  'send me a friend request',
  'fk',
  ' white supremacy',
  'page in my bio',
  's*attle',
  'shattle',
  'dont care',
  'gayer',
  '@scott_stepfx2',
  'rate my foto ',
  '@harrybrandon_fxt',
  '𝓘 𝒽𝒶𝓋𝓮 𝒶 𝒷𝒾𝓇𝓉𝒽𝒹𝒶𝓎 𝓉𝓸𝒹𝒶𝓎 𝓅𝓁𝓮𝒶𝓈𝓮 𝓁𝒾𝓀𝓮 𝓂𝓎 𝓅𝒽𝓸𝓉𝓸',
  '𝗰𝗵𝗲𝗰𝗸 𝗼𝘂𝘁 ',
  '𝓥𝓲𝓼𝓲𝓽 𝓶𝓮',
  '@isabellarobert_',
  'sending a request',
  '@mary___anderson5',
  'p a i d t o b e h o m e .c o m',
  '@floral_expert_miner1',
  "i'm a cartoonist ",
  "𝓹𝓵𝓪𝔂 𝔀𝓲𝓽𝓱 𝓶𝓮 𝓲'𝓶 𝓪 𝓫𝓲𝓽 𝓫𝓸𝓻𝓮𝓭 𝓬𝓱𝓮𝓬𝓴 𝓹𝓻𝓸𝓯𝓲𝓵𝓮 𝓰𝓾𝔂𝓼",
  '@suzanne_crypto_miner',
  '𝑅𝑎𝑡𝑒 𝑚𝑦',
  'boycott ea sports',
  'publicity',
  "i'm alone and lonely ",
  'boycottea',
  '𝕎𝕖 𝕤𝕖𝕝𝕝',
  'boycotteasports',
  '@official_consultant.debbie',
  "don't look at my story",
  "trump's ",
  'trump2020',
  'far right',
  'impeach',
  'coup',
  'far left',
  'impeachment',
  'qanon',
  "don't not view my story",
  'radicals',
  '@ariellefx',
  'the big one is delicious but the long lasting one is more delicious',
  'i need something big, long, hard and durable, where do i go?',
  '@earn_with_mariselvaleryfx',
  "i'm looking for a discreet relationship",
  'vachina',
  'storming the capitol',
  '@educator_lewisfx',
  '@earn_with_dianne',
  'wattch my squirtiing videos i just upload and preparre a tisue to cleans your speerm latter ',
  'storming the capital',
  'militant left',
  'trumptard',
  "because he's black",
  '@alice_klein_profit',
  'paying off the debts',
  'long cucumber',
  'why does the camera work poorly on iphone 11? ',
  'i go crazy remembering your longer!',
  'does anyone have',
  ' like long and tough😍',
  'i need something warm and delicious🥰',
  'does anyone have something long and hard🤤🤫😝',
  'spit',
  'spits',
  'spitting',
  'i post secret dark web leaks ',
  'real man, show your charm ❤️',
  'ⅾ𝜊𝘦𝗌 𝗌𝜊𝓂𝘦𝜊𝜼𝘦 𝜔a𝜼𝘁 𝘁𝜊 𝜔𝘳і𝘁𝘦 𝓂𝘦?',
  '@thereal_lillian',
  '@bradley_daniella_fx',
  'she said no',
  'consensual',
  'consented',
  'concented',
  'sandwich',
  'sandwiches',
  'bots',
  'loot',
  'looted',
  'fraud',
  '@brad_neeld ',
  '@earn_with_roselyncryptotrade',
  'feeling sad today😔😔',
  '@tradewith_loncross',
  'today i had a very hot dream😈',
  'your daily reminder to drink water',
  'i need 🧡a long hard😜',
  '@paid_with__elizabeth_',
  'ᴺᵉʷ ʸᵉᵃʳ ⁿᵉʷ ᵇᵒʸᶠʳⁱᵉⁿᵈ',
  // 'i like men who are diligent in sports because they must be strong when making babies together 🤭',
  'cute sweet girl looking',
  '@invest_with_dianafx',
  '@md__nelson',
  'play with my😋',
  '🔰🔴𝐖𝐀𝐓𝐂𝐇 𝐋𝐈𝐕𝐄🔴a_life_in_ruins',
  '@cherrymac_fx1',
  'make hockey violent again',
  'recallbettman',
  'big pharma',
  'leftist',
  'rightist',
  'i really like 17.5 centimeters, what length do you have ?????🤩🤩🤩🥒',
  'i want to join you ',
  'you look superr cute!!🌟😘',
  '@mr_alanprenuer',
  '@jay_albert232',
  'big and long ',
  'hit me up',
  'i need somebody tonight 💞',
  'live 🔴hd live🔴►►',
  'i need a boyfriend',
  'breakingthebook.com',
  'long and big',
  'you are so nice',
  'opinion on my video production',
  'help me get',
  'my boyfriend left me ',
  'i post',
  '@victordariofx',
  'bang this evening ',
  'big, long and durable',
  'love this ????????????',
  'adipex😝',
  'что делать если много денег',
  'he nailed me all night long😛💝',
  '@loren___pagan',
  '𝓫𝓮𝓼𝓽 𝓹𝓾𝓫𝓵𝓲𝓬𝓪𝓽𝓲𝓸𝓷 𝓯𝓸𝓻 𝓽𝓸𝓭𝓪𝔂',
  'i am wet from morning to night😊',
  'my boyfriеnd left mе😢',
  'i slowly take off my drеss😍',
  'ｉ ｄｏｎｔ ｐｏｓｔ ｎａｋｅｄ ｇｉｒｌｓ ｏｒ ａｄｓ， ｊｕｓｔ ｍｅｍｅｓ．．',
  '@earnwith_lawson',
  'my body burns because of you🧡💝',
  'i literally steal memes off private accounts and post them publicly so you don’t have to follow! 💯',
  'feeling so lonely😢',
  '@olivia_jaden_fx',
  'savesheikhjarrah',
  'savepalestine',
  'straightphobia',
  'ridiculous acronym',
  'ha gayyyyy',
  'relax your throat',
  'staitphobia',
  'talk about go soft',
  'pick one already',
  '🐑🐑🐑 ',
  'sheeps',
  'experiemental jab',
  '@louis_elizabeth_f',
  'the jab',
  'nah he tweakin',
  "don't see my story",
  'lооking fоr а friеnd',
  'haha???',
  'something long and hard',
  'i need а bоуfriend',
  'make love to me',
  'interested in me',
  'stay at home job',
  'boycottnhl',
  'sus',
  'strait day',
  'strait month',
  'straight day',
  'straight month',
  's0domy',
  'straight white',
  'strait white',
  '📉',
  '👉👈',
  '𝐈 𝐢𝐧𝐯𝐞𝐬𝐭𝐞𝐝',
  'fire chris lee',
  'chris lee',
  'firelee',
  'firechrislee',
  'corrupt',
  '@fx_with_melinda_',
  '𝗜𝗠 𝗣𝗔𝗬𝗜𝗡𝗚 𝗢𝗙𝗙 𝗗𝗘𝗕𝗧𝗦',
  '@stephanie_expert_miner',
  'rigging',
  '@tradewithkatee_',
  'fixyourref',
  'firetherefs',
  'firechrisslee',
  'cris lee',
  'unfollow',
  'unfollowing',
  'mental disorder',
  'taliban',
  'afghanistan',
  'who cares',
  'nobody cares',
  'shower',
  'message me',
  '@james_fx_expert_trade_',
  '@ceo_scott_wallace',
  '@educator_florencehendricks',
  '@samuel.trades',
  '@robertmarkfx9',
  '@moonskid',
  '@earn_with_jayne_farell',
  'white history month',
  'communism',
  'communist',
  'biggots',
  'bigot',
  'bogot',
  'felony',
  'drug words',
  'gangster',
  'gang',
  'drug',
  'overdose',
  'drugs',
  'pedos',
  'moan',
  'antivax',
  'antivaxxer',
  'skin',
  'race',
  '@esme_park_fx',
  '@nicole_andrew.fx',
  'russia',
  'russian',
  'russians',
  'ukraine',
  'ukrainian',
  'ukrainians',
  'sanction ovi',
  'sanctionovi',
  "putin's",
  "russian's",
  'putinpuppet',
  '🇷🇺',
  'privetik',
  'these comments will be nice i’m sure',
  'homophobes',
  'comments',
  '@isabella_forex937',
  "don't judge me after you see my stories",
  'on mute',
  'down our throat',
  'down our throats',
  'down my throat',
  'hockey is not for everyone',
  'fruity',
  'two genders',
  'suss',
  'sussy',
  'hockeyisntforeveryone',
  'wokeness',
  'xx',
  'xy',
  'Cunt',
];

export const checkOnBannedPhrase = (value: string) => {
  return Boolean(
    BannedPhrase.find(
      (word: string) =>
        value.length > 3 && value.toLowerCase().includes(word.toLowerCase()),
    ),
  );
};
