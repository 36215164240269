import { FC } from 'react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

import { ProfileForm } from '@/pages/Profile/ProfileForm';

export const ProfileModal: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p='20px 10px 30px'>
          <ModalCloseButton />
          <ModalBody>
            <ProfileForm />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
