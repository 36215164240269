import { useMediaQuery } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { SET_SHOULD_UPDATE_PROFILE } from '@/store/slices/app.slice';

import { MediaQueriesEnum } from '@/enum/mediaQueries.enum';

export const useSettings = () => {
  const dispatch = useDispatch();
  const [isDesktop, isTablet, isMobile] = useMediaQuery(
    [
      MediaQueriesEnum.isDesktop,
      MediaQueriesEnum.isTablet,
      MediaQueriesEnum.isMobile,
    ],
    { ssr: false },
  );

  const setShouldUpdateProfile = (value: boolean) =>
    dispatch(SET_SHOULD_UPDATE_PROFILE(value));

  return {
    isMobile,
    isTablet,
    isDesktop,
    setShouldUpdateProfile,
  };
};
