import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from '@/App';
import { AuthProvider } from '@/providers/AuthProvider';
import { KeycloakProvider } from '@/providers/Keycloak.provider';
import { MetadataProvider } from '@/providers/Metadata.provider';
import { setupStore } from '@/store';
import theme from '@/theme';

import { LocalStorageProvider } from '@/context/localStorage.context';

import './i18n';

import '@/global.css';

const store = setupStore();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <BrowserRouter>
    <LocalStorageProvider>
      <Provider store={store}>
        <KeycloakProvider>
          <ChakraProvider theme={theme}>
            <AuthProvider>
              <MetadataProvider />
              <App />
            </AuthProvider>
          </ChakraProvider>
        </KeycloakProvider>
      </Provider>
    </LocalStorageProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
