import { Flex, Grid, GridItem } from '@chakra-ui/react';

import { Logo } from '@/components/Logo';

import { LoginAndLogOut } from './LoginAndLogOut';
import { GameRulesButton } from './components/GameRulesButton';
import { ProfileButton } from './components/ProfileButton';

export const DesktopHeader = () => {
  return (
    <>
      <Grid gridTemplateColumns='300px 1fr' h='72px' p='0 40px'>
        <GridItem>
          <Logo />
        </GridItem>
        <Flex align='center' gap='20px' justify='end'>
          <GameRulesButton />
          <ProfileButton />
          <LoginAndLogOut />
        </Flex>
      </Grid>
    </>
  );
};
