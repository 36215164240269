import { FC, useMemo } from 'react';

import { useTheme } from '@chakra-ui/react';
import Select, { StylesConfig } from 'react-select';

export const StyledSelect: FC<any> = ({ isDisabled, isInvalid, ...props }) => {
  const theme = useTheme();

  const customStyles = useMemo<StylesConfig<any, false, any>>(
    () => ({
      control: (provided) => ({
        ...provided,
        background: theme.colors.PrimaryDarkPurple,
        height: '48px',
        border: '0',
        outline: '0',
        boxShadow: 'none',
        borderRadius: '8px',
        opacity: isDisabled ? '0.4' : '1',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        borderBottom: '1px solid',
        borderColor: isInvalid
          ? `${theme.colors.countrySelect.invalid} !important`
          : `${theme.colors.countrySelect.border} !important`,
        padding: '0 16px',

        '&:hover': {
          borderColor: theme.colors.countrySelect.border,
        },
        img: {
          width: '22px',
          height: '22px',
        },
      }),
      placeholder: (props) => ({
        ...props,
        fontSize: '16px',
        fontWeight: '600',
        color: theme.colors.countrySelect.placeholder,
      }),
      singleValue: (props) => ({
        ...props,
        fontSize: '16px',
        fontWeight: '600',
        color: theme.colors.countrySelect.text,
      }),
      valueContainer: (props) => ({
        ...props,
        color: '#fff',
        padding: 0,
      }),
      indicatorsContainer: (props) => ({
        ...props,
      }),
      container: (props) => ({
        ...props,
        opacity: isDisabled ? '0.4' : '1',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }),
      option: (props) => ({
        ...props,
        cursor: 'pointer',
        color: theme.colors.countrySelect.text,
        marginBottom: '5px',

        img: {
          width: '30px',
          height: 'auto',
        },
      }),
      menuPortal: (provided) => ({
        ...provided,
        border: 'none',
        zIndex: 9999,
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        marginTop: '0px',
        border: 'none',
        boxShadow: 'none',
      }),
      menuList: (props) => ({
        ...props,
        zIndex: 9999,
        paddingTop: '0px',
        borderTop: '0',
        cursor: 'pointer',
        color: theme.colors.countrySelect.text,
        background: theme.colors.countrySelect.menu,
        borderColor: isInvalid
          ? theme.colors.countrySelect.invalid
          : theme.colors.countrySelect.border,
      }),
      dropdownIndicator: (props) => ({
        ...props,
        paddingRight: '0',
        paddingLeft: '0',
      }),
      indicatorSeparator: () => ({ display: 'none' }),
    }),
    [theme.colors, isDisabled, isInvalid],
  );

  return (
    <>
      <Select {...props} styles={customStyles} />
    </>
  );
};
