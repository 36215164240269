import React from 'react';

import { FormControlProps } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import DebounceInput from '@/elements/DebounceInput';

export type FormDebounceInputProps = Omit<
  FormControlProps,
  'label' | 'onChange' | 'defaultValue'
> & {
  hasTooltip?: boolean;
  label?: string | React.ReactNode;
  name: string;
  placeholder?: string;
  tooltipText?: string | React.ReactNode;
  type?: string;
  value?: string;
  variant?: string;
  hasLeftElement?: boolean;
  leftElement?: string | React.ReactNode;
  hintText?: string;
  debounceTimeout?: number;
};

const FormDebounceInput: React.FC<FormDebounceInputProps> = ({
  name,
  variant,
  ...rest
}) => {
  const {
    formState: { errors },
    register,
    getValues,
  } = useFormContext();

  const error = errors[name]?.message as string;
  const isSuccess = !errors[name]?.message && getValues(name);

  return (
    <DebounceInput
      containerProps={{ mb: 'md' }}
      error={error}
      isInvalid={Boolean(errors[name]?.message)}
      isSuccess={isSuccess}
      variant={variant}
      {...register(name)}
      {...rest}
    />
  );
};

export default FormDebounceInput;
