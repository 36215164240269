import { useMemo } from 'react';

import { Flex, Grid, IconButton } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '@/assets/icons/backArrow.svg';

import { Logo } from '@/components/Logo/index';

import { useSidebarContext } from '@/context/sidebar.context';

import { RoutesEnum } from '@/enum/routes.enum';

import { ReactComponent as Burger2 } from './assets/burger-icon.svg';

export const MobileHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { onSidebarOpen } = useSidebarContext();

  const isBackButtonVisible = useMemo(
    () => pathname === RoutesEnum.PROFILE,
    [pathname],
  );

  return (
    <>
      <Grid
        bg='white'
        gap='10px'
        gridTemplateColumns='70px 1fr 70px'
        h='72px'
        position='sticky'
        top={0}
        zIndex={999}
      >
        <Flex align='center' justify='center'>
          {isBackButtonVisible ? (
            <BackArrow cursor='pointer' onClick={() => navigate(-1)} />
          ) : (
            <IconButton
              aria-label='menu'
              icon={<Burger2 />}
              onClick={onSidebarOpen}
            />
          )}
        </Flex>

        <Flex align='center' justify='center'>
          <Logo />
        </Flex>

        <Flex />
      </Grid>
    </>
  );
};
