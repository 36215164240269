import React, { lazy } from 'react';

import { RoutesEnum } from '@/enum/routes.enum';

const ProfilePage = lazy(() => import('@/pages/Profile'));
const UnsubscribePage = lazy(() => import('@/pages/Unsubscribe'));
const LoginPage = lazy(() => import('@/pages/Login'));
const ForgotPasswordPage = lazy(() => import('@/pages/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('@/pages/ResetPassword'));
const HomePage = lazy(() => import('@/pages/Home'));
const SSOEntryPage = lazy(() => import('@/pages/SSOEntry'));
const RedirectCallbackPage = lazy(() => import('@/pages/RedirectCallback'));
const UsernameUpdatePage = lazy(() => import('@/pages/UsernameUpdatePage'));

interface IRoutes {
  path: RoutesEnum;
  element: React.ReactNode;
  children?: IRoutes[];
}

export const AppRoutes: IRoutes[] = [
  // {
  //   path: RoutesEnum.PROFILE,
  //   element: <ProfilePage />,
  // },
  {
    path: RoutesEnum.UNSUB,
    element: <UnsubscribePage />,
  },
  {
    path: RoutesEnum.HOME,
    element: <HomePage />,
  },
  // {
  //   path: RoutesEnum.LOGIN,
  //   element: <LoginPage />,
  // },
  // {
  //   path: RoutesEnum.FORGOT,
  //   element: <ForgotPasswordPage />,
  // },
  // {
  //   path: RoutesEnum.RESET,
  //   element: <ResetPasswordPage />,
  // },
  {
    path: RoutesEnum.SSOENTRY,
    element: <SSOEntryPage />,
  },
  {
    path: RoutesEnum.REDIRECT_CALLBACK,
    element: <RedirectCallbackPage />,
  },
  // {
  //   path: RoutesEnum.USERNAME_UPDATE,
  //   element: <UsernameUpdatePage />,
  // },
];
