import React, { useCallback } from 'react';

import {
  Box,
  useTheme,
  useToast as useChakraToast,
  UseToastOptions,
} from '@chakra-ui/react';

import { ToastTypesEnum } from '@/enum/toastTypes.enum';

export const useToast = () => {
  const toast = useChakraToast();
  const theme = useTheme();

  const customToast = useCallback(
    (
      message: string | React.ReactNode,
      type: ToastTypesEnum,
      params?: UseToastOptions,
    ) => {
      toast({
        position: 'top',
        isClosable: true,
        duration: 3000,
        ...params,
        render: () => (
          <Box
            bg={
              type === ToastTypesEnum.success
                ? theme.colors.toast.success
                : theme.colors.toast.error
            }
            color={theme.colors.toast.text}
            fontWeight='bold'
            p={3}
          >
            {message}
          </Box>
        ),
      });
    },
    [
      theme.colors.toast.error,
      theme.colors.toast.success,
      theme.colors.toast.text,
      toast,
    ],
  );

  return customToast;
};
