import * as yup from 'yup';

import { TFunc, tKeys } from '@/localization';

export const regExpForEmail =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const regExpForPassword = new RegExp(
  '(?=.*[0-9])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}',
);
export const lowerCaseRegexp = /^(?=.*[a-z])/;
export const upperCaseRegexp = /^(?=.*[A-Z])/;
export const containNumberRegexp = /^(?=.*[0-9])/;
export const neededLengthRegexp = /^.{8,20}$/;

export const isEmailFormatValid = (t: TFunc) => {
  return yup.string().matches(regExpForEmail, t(tKeys.invalidEmailFormat));
};

export const isPasswordValid = (t: TFunc) => {
  return yup
    .string()
    .matches(regExpForPassword, t(tKeys.passwordNotMeetCriteria));
};

export const isLowerCase = (t: TFunc) => {
  return yup
    .string()
    .matches(lowerCaseRegexp, t(tKeys.mustContainOneLowercase));
};

export const isUpperCase = (t: TFunc) => {
  return yup
    .string()
    .matches(upperCaseRegexp, t(tKeys.mustContainOneUppercase));
};

export const isContainNumber = (t: TFunc) => {
  return yup
    .string()
    .matches(containNumberRegexp, t(tKeys.mustContainOneNumber));
};

export const haveEightCharLength = (t: TFunc) => {
  return yup
    .string()
    .matches(neededLengthRegexp, t(tKeys.mustContainFrom8To20Characters));
};
