import { Flex, Text, useDisclosure } from '@chakra-ui/react';

import { GameRulesModal } from '@/components/Header/components/GameRulesModal';

import { trackEvent } from '@/utils/analyticTracks';

import { ReactComponent as InfoIcon } from '../assets/circle-info-outline.svg';

export const GameRulesButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onButtonClick = () => {
    trackEvent({
      event: 'button_clicked',
      button_text: 'Game Rules',
      location: 'Gamezone Lobby Navigation',
    });

    onOpen();
  };

  return (
    <>
      <Flex
        align='center'
        as='button'
        fontWeight='bold'
        gap='8px'
        onClick={onButtonClick}
      >
        <InfoIcon />
        <Text>Game Rules</Text>
      </Flex>

      <GameRulesModal {...{ isOpen, onClose }} />
    </>
  );
};
