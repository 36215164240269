import { ComponentStyleConfig } from '@chakra-ui/react';

const defaultButtonStyles = {
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  // width: 'auto',
  // padding: '8px 27px',
  // textAlign: 'center',
  // fontWeight: '700',
  // fontSize: '17px',
  // lineHeight: '20px',
  // color: 'button.defaultText',
  // borderRadius: '8px',
  // border: 'none',
  // cursor: 'pointer',
  // userSelect: 'none',
  // outline: 'none',
  // boxShadow: 'none',
  // textDecoration: 'none',
};

const Button: ComponentStyleConfig = {
  baseStyle: {
    outline: 'none',
    _hover: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
    _active: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  sizes: {
    sm: {
      fontSize: '17px',
      px: '12px',
      py: '8px',
    },
    md: {
      fontSize: '17px',
      px: '27px',
      py: '8px',
    },
  },
  variants: {
    primary: {
      ...defaultButtonStyles,
      background: 'button.primary.bg',
      border: '2px solid',
      borderColor: 'button.primary.bg',
      color: 'button.primary.text',
      _hover: {
        textDecoration: 'none',
        _disabled: {
          background: 'button.primary.disabledBg',
          outline: 'none',
        },
      },
    },
    secondary: {
      ...defaultButtonStyles,
      background: 'button.secondary.bg',
      border: '2px solid',
      borderColor: 'button.secondary.border',
      color: 'button.secondary.text',
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        _disabled: {
          background: 'button.secondary.disabledBg',
          outline: 'none',
        },
      },
    },
    outlined: {
      ...defaultButtonStyles,
      border: '2px solid',
      borderColor: 'PrimaryPurple',
      color: 'PrimaryPurple',
    },
    ghost: {
      ...defaultButtonStyles,
      _hover: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'none',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
      },
      _active: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'ghost',
  },
};

export default Button;
