export enum StorageKeysEnum {
  jwt = 'jwt',
  i18nextLng = 'i18nextLng',
  tmpToken = 'tmpToken',
  user_id = 'user_id',
  clickedGameURL = 'clickedGameURL',
  token = 'token',
  redirectURL = 'redirectURL',
  kcToken = 'kc_token',
  kcRefreshToken = 'kc_refreshToken',
  username = 'username',
}
