import { FC } from 'react';

import {
  ListItem,
  Mark,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  OrderedList,
  Link,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react';

export const GameRulesModal: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const paragraphIndent = '12px';

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW='90vw'>
          <ModalCloseButton />
          <ModalBody>
            <>
              <Text fontWeight='700' mt='16px' textAlign='center'>
                WTA Pick ‘Em &amp; Streaks Game 2024
              </Text>
              <Text fontWeight='700' textAlign='center'>
                OFFICIAL RULES
              </Text>
              <Text my={paragraphIndent}>
                These are the official rules (the &quot;Official Rules&quot;)
                that govern the <i>WTA Pick ‘Em &amp; Streaks Game 2024 </i>(the
                “<Mark fontWeight='700'>Game</Mark>
                ”) and the relationship between you and the Sponsor (as defined
                in Section 1 below) in relation to the Game. By participating in
                this Game, you confirm you have legal capacity to take part in
                the Game and you agree to comply with and abide by these
                Official Rules.
              </Text>
              <Text fontWeight='700' my={paragraphIndent}>
                NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE OR PAYMENT OF
                ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING.
              </Text>
              <Text fontWeight='700' my={paragraphIndent}>
                THE GAME IS DESIGNED TO ADVERTISE AND PROMOTE WOMEN’S
                PROFESSIONAL TENNIS AND IS NOT GAMBLING.
              </Text>
              <Text fontWeight='700' my={paragraphIndent}>
                You must have Internet access AND a valid email address to
                participate. PLEase read our privacy policy BEFORE
                PARTICIPATING; SEE below at SECTION 16.
              </Text>
              <Text fontWeight='700' my={paragraphIndent}>
                Eligibility: GAME open only to legal residents OF the 50 United
                States and the District of Columbia, Canada AND THE UNITED
                KINGDOM WHO ARE (1) at least eighteen (18) years or the age of
                majority in their country/province/state of residence, whichever
                is older, at the time of REGISTRATION and have mental capacity
                to enter into a legally binding contract and (2) who have
                internet access and a valid and active email account. VOID
                elseWHERE and where PROHIBITED BY LAW .
              </Text>
              <Text fontWeight='700' my={paragraphIndent}>
                This promotion is in no way sponsored, endorsed or administered
                by, or associated with X, Facebook or Instagram. By
                participating in the promotion, you are providing your
                information to Sponsor (defined below), not to X Corp. or Meta
                Platforms, Inc. For the avoidance of doubt, Facebook, X or
                Instagram shall have no responsibility or liability to you in
                respect of the Game.
              </Text>
              <OrderedList>
                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Sponsor:
                    </Mark>{' '}
                    WTA Ventures Operations, LLC,100 2nd Avenue South, Suite
                    300-N, St. Petersburg, Florida 33701 U.S.A. (“
                    <Mark fontWeight='700'>Sponsor</Mark>”).
                  </Text>
                </ListItem>
                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Administrator:
                    </Mark>{' '}
                    Probability, LLC, 2 Woodstock Meadow Ln, PO Box 1484,
                    Woodstock, NY 12498 U.S.A. (“
                    <Mark fontWeight='700'>Administrator</Mark>”).
                  </Text>
                </ListItem>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Eligibility:
                    </Mark>{' '}
                    The <i>WTA Pick ‘Em &amp; Streaks Game 2024</i>
                    is open only to legal residents of the 50 United States and
                    the District of Columbia, Canada and the United Kingdom, who
                    have mental capacity to enter into a legally binding
                    contract and are at least eighteen (18) years of age or the
                    age of majority in their country, province, or state of
                    residence, whichever is older, at the time of registration.
                    Any individuals (including but not limited to employees,
                    consultants, independent contractors, and interns) who have,
                    within the past six (6) months, performed services for
                    Sponsor, Administrator, or any organizations responsible for
                    sponsoring, fulfilling, administering, advertising or
                    promoting the Game or awarding any prize and/or their
                    respective parent, subsidiary, affiliated, and successor
                    companies (collectively, the “
                    <Mark fontWeight='700'>Promotion Entities</Mark>”), and
                    immediate family and household members of such individuals,
                    are not eligible to register or participate. In addition,
                    current WTA Tour players, as well as their coaches, practice
                    partners and other assistants, management/representation are
                    not eligible to register or participate. Further, the
                    immediate family and household members of any individuals of
                    any of the preceding categories are not eligible to register
                    or participate. “
                    <Mark fontWeight='700'>Immediate family members</Mark>”
                    shall mean parents, step-parents, children, step-children,
                    siblings, step-siblings, or spouses, regardless of where
                    they live. “<Mark fontWeight='700'>Household members</Mark>”
                    shall mean people who share the same residence at least
                    three (3) months a year, whether related or not.
                  </Text>
                </ListItem>

                <Text fontWeight='700' my={paragraphIndent}>
                  <Mark textDecor='underline'>
                    Registrants may not have been convicted of a felony or a
                    crime of moral turpitude
                  </Mark>
                  . FOR RESIDENTS OF THE UNITED STATES, AND CANADA ONLY: By
                  registering, each Game Registrant (“Registrant”) understands
                  and agrees that, if selected as a potential Winner, he/she
                  will be required to consent to a background check to verify
                  eligibility; and, failure to sign such consents and
                  authorizations and/or to furnish all required information will
                  result in disqualification. (The preceding requirement does
                  NOT apply to Registrants who are residents of the United
                  Kingdom.)
                </Text>

                <Text fontWeight='700' my={paragraphIndent}>
                  In addition, Sponsor reserves the right in its sole
                  discretion, to disqualify a potential Winner if the results of
                  such background check reveal that such Registrant is not
                  eligible to participate in the Game (for Registrants who are
                  residents of the United States or Canada) or if the Registrant
                  has been convicted of a felony or a crime of moral turpitude
                  (for all Registrants other than Registrants who are residents
                  of the United Kingdom (in relation to felonies only), as
                  determined by Sponsor in its sole discretion. If disqualified,
                  a potential Winner will not receive any compensation of any
                  kind nor will Sponsor or the other Promotion Entities have any
                  liability to him/her under any legal theory.
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Game Schedule:
                    </Mark>{' '}
                    United States Eastern Time (“E.T.”) (e.g., New York time),
                    corresponding to Coordinated Universal Time (UTC) minus four
                    (4) hours. For example, where the stated time is 12:00 p.m.
                    E.T., this time corresponds to 4:00 p.m. UTC of the same
                    day, and where the stated time is 11:59 p.m. E.T., this
                    corresponds to 3:59 a.m. UTC in the morning of the following
                    day; but, from November 3, 2024 at 2:00 a.m. E.T. until
                    November 9, 2024, the E.T. corresponds to UTC minus
                    <i>five (5)</i>
                    hours.{' '}
                    <Mark fontWeight='700'>
                      IMPORTANT NOTICE TO REGISTRANTS: ALL TIMES LISTED
                      THROUGHOUT THE OFFICIAL RULES ARE IN ET. REGISTRANTS ARE
                      SOLELY RESPONSIBLE FOR DETERMINING THE CORRESPONDING TIME
                      IN THEIR RESPECTIVE JURISDICTIONS.
                    </Mark>
                  </Text>
                </ListItem>

                <Text my={paragraphIndent}>
                  The Game begins on Tuesday, May 7, 2024 and ends at the start
                  of the final match of the WTA Finals scheduled to take place
                  on Saturday, November 9, 2024 (“Promotion Period”). The
                  Promotion Period consists of “Swings”:(a) Swing 1: May 7, 2024
                  – August 4, 2024; (b) Swing 2: August 6, 2024 – November 9,
                  2024.
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Objective &amp; Overview of Game:
                    </Mark>
                  </Text>
                </ListItem>

                <Text my={paragraphIndent}>
                  In the Game, Registrants attempt to accumulate the most points
                  by making correct predictions in response to posted questions
                  about Hologic WTA Tour tennis tournaments.** During each day
                  of the Promotion Period (also referred to as “Tournament
                  Day”), Sponsor will post one (1) or more questions regarding
                  one of the scheduled matches taking place on that Tournament
                  Day and/or otherwise corresponding to the Tournament
                  generally. A sample question might be:
                  <i>
                    in today’s match between Caroline Garcia and Coco Gauff, who
                    will record more aces?
                  </i>
                </Text>

                <Text my={paragraphIndent}>
                  If a given Tournament Day’s question(s) is/are answered
                  correctly, the Registrant will receive Points for each
                  Tournament Day’s question correctly answered.† Depending on
                  the specific Tournament Day’s question, a correct answer will
                  result in either one (1), two (2) or three (3) Points; the
                  number of Points available will be listed next to the
                  corresponding Tournament Day’s question.(Registrants start
                  with zero (0) Points.) In addition, beginning on Monday, July
                  29, 2024, the Registrant builds a streak by correctly
                  answering the Tournament Day Streak Question on consecutive
                  Tournament Days (“Streak”). (For clarity, beginning on July
                  29, 2024, if there is only one [1] Tournament Day’s question
                  on a given Tournament Day, that question will also function as
                  the Tournament Day Streak Question; if there are multiple
                  Tournament Day’s questions on a given Tournament Day, only one
                  [1] of these questions will also function as the Tournament
                  Day Streak Question. In all cases, the Tournament Day Streak
                  Question will be clearly designated as such.) Regardless of
                  their Point totals and the number of consecutive Tournament
                  Days prior to July 29, 2024 on which Registrant correctly
                  answered the Tournament Day’s question(s), all Registrants
                  start with a Streak of zero (0).
                </Text>

                <Text my={paragraphIndent}>
                  †As Monday, August 5, 2024 falls between Swing 1 and Swing 2,
                  no Points will be awarded for correctly answering questions on
                  August 5. But, the Streak portion of the Game is still in
                  effect on August 5 and, to keep his/her Streak alive, a
                  Registrant must correctly answer the Tournament Day Streak
                  Question on August 5.
                </Text>

                <Text my={paragraphIndent}>
                  If a Registrant<u>incorrectly</u>
                  answers or fails to answer a Tournament Day’s question, the
                  Registrant will not receive any Points for that Tournament
                  Day’s question. (Points are not deducted from a Registrant for
                  incorrect answers or failing to answer a Tournament Day’s
                  question. If there are multiple Tournament Day’s questions on
                  a given Tournament Day, incorrectly answering or failing to
                  answer one [1] Tournament Day’s question will not affect a
                  Registrant’s ability to receive Points for correctly answering
                  other Tournament Day’s question[s] on that Tournament Day
                  including the Tournament Day Streak Question [beginning on
                  July 29, 2024.]) In addition, beginning on July 29, 2024,
                  incorrectly answering or failing to answer the Tournament Day
                  Streak Question will result in the Registrant’s Streak being
                  “broken” and he/she will have to start a new Streak at zero
                  (0).
                </Text>

                <Text my={paragraphIndent}>
                  In all cases, the official sanctioned data/results of matches,
                  as of twenty-hours (24) hours after the conclusion of the
                  corresponding match, will be used to determine whether an
                  answer to any given Tournament Day’s question is correct.
                  (Should the data/results of a match be corrected<i></i>
                  <i>
                    <u>after</u>
                  </i>
                  such twenty-four [24] hour period, Registrant’s Point totals
                  and, if the affected Tournament Day’s question is the
                  Tournament Day Streak Question, the Registrant’s Streak will
                  NOT be affected.) For Tournament Day’s questions including
                  Tournament Day Streak Questions which address a given
                  Tournament generally (and not with respect to match play at
                  that Tournament), Sponsor shall determine the correct answers
                  to such questions in its sole discretion. Sponsor’s decisions
                  in this regard (as well as all other decisions of Sponsor and
                  Administrator relative to the Game) are final and binding in
                  all respects and not subject to appeal.
                </Text>

                <Text my={paragraphIndent}>
                  **Hologic WTA Tour tennis tournaments consist of all
                  sanctioned tournaments: WTA 250, WTA 500, WTA 1000 and WTA
                  Finals. (For clarity, WTA 125 and Grand Slam tournaments are
                  excluded.)
                </Text>

                <Text my={paragraphIndent}>
                  If any question on a given Tournament Day’ does not result in
                  a definitive answer, no Points will be awarded for that
                  question. In addition beginning on July 29, 2024, if a
                  question on a given Tournament Day does not result in a
                  definitive answer and it is also the Tournament Day Streak
                  Question, Streaks will not be affected. To use the above
                  example,
                  <i>
                    Caroline Garcia and Coco Gauff each record 10 aces in their
                    match on the corresponding Tournament Day.
                  </i>
                  A further example is a match being suspended or postponed on a
                  given Tournament Day due to inclement weather (or any other
                  reason).
                </Text>

                <Text my={paragraphIndent}>
                  In the unlikely event that the only question(s) on a given
                  Tournament Day relate to match play and no matches are played
                  on that Tournament Day, no Points will be awarded for that
                  Tournament Day; and, if this occurs on or after July 29, 2024,
                  Streaks will not be affected.
                </Text>

                <Text my={paragraphIndent}>
                  For all purposes,Sponsor’s computer (at its offices in St.
                  Petersburg, Florida U.S.A.) is the official clock for the
                  Game.
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Registration:
                    </Mark>
                  </Text>
                </ListItem>

                <Text my={paragraphIndent}>
                  During the Promotion Period, navigate the internet to
                  Fantasy.wtatennis.com (“Website”). You will be directed to
                  complete the online registration form. You will be asked to
                  provide your full name, date of birth, email address, city and
                  state/province/country as well as to create a username and
                  password for the submission of answers to Tournament Day
                  questions.
                </Text>

                <Text my={paragraphIndent}>
                  Your username is subject to approval by Sponsor in its sole
                  discretion. Obscene, hateful, offensive or otherwise
                  inappropriate usernames will NOT be accepted. BE SURE TO
                  SAFEGUARD YOUR PASSWORD SO AS TO PREVENT UNAUTHORIZED ACCESS
                  TO YOUR REGISTRATION. Your email address will be used to
                  contact you if you are a potential prize Winner, if you forget
                  your password and/or username or if you need to re-set your
                  password and/or username.
                </Text>

                <Text my={paragraphIndent}>
                  CLICK THE CHECK BOX SIGNIFYING THAT YOU MEET THE GAME’S
                  ELIGIBILITY REQUIREMENTS AND HAVE READ AND AGREE TO THESE
                  OFFICIAL RULES. IF YOU DO NOT CHECK THE BOX INDICATING YOUR
                  CONFIRMATION OF AND AGREEMENT TO THE ABOVE, YOU WILL NOT BE
                  REGISTERED FOR THE GAME, CANNOT ENGAGE IN GAME PLAY AND CANNOT
                  WIN ANY PRIZES IN THE GAME.
                </Text>

                <Text my={paragraphIndent}>
                  THERE IS A LIMIT OF ONE (1) REGISTRATION PER PERSON DURING THE
                  PROMOTION PERIOD. ANY ATTEMPT TO REGISTER MULTIPLE TIMES VOIDS
                  ALL REGISTRATIONS/GAME PLAYS AND WILL RESULT IN
                  DISQUALIFICATION FROM THE GAME AND PERHAPS OTHER PROMOTIONS
                  OFFERED BY SPONSOR OR ITS AFFILIATED/RELATED ENTITIES.
                </Text>

                <Text my={paragraphIndent}>
                  In case of a dispute over the identity of a Registrant, the
                  authorized account holder of the email address used to
                  register will be deemed to be the Registrant. “Authorized
                  account holder” is defined as the person who is assigned to an
                  email address by an internet access provider, online service
                  provider or other organization that is responsible for
                  assigning email addresses for the domain associated with the
                  submitted email address.
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Game Play:
                    </Mark>
                  </Text>
                </ListItem>

                <Text my={paragraphIndent}>
                  At a certain time . on a given Tournament Day, that Tournament
                  Day’s question(s) will be posted on the Website. If multiple
                  questions are posted on the same Tournament Day, all questions
                  may or may not be posted at or around the same time.
                  <u>
                    The time of posting of question(s) on a given Tournament Day
                    will vary by Tournament Day.
                  </u>
                </Text>

                <Text my={paragraphIndent}>
                  After signing in, input your answer(s) to that Tournament
                  Day’s question(s) by the posted deadline for each question.
                  <u>
                    The deadline for answering question(s) on a given Tournament
                    Day will vary by Tournament Day.
                  </u>
                </Text>

                <Text my={paragraphIndent}>
                  <u>
                    A given Tournament Day’s question(s) will only be available
                    during the indicated times. Missing the opportunity to
                    answer question(s) for a given Tournament Day for any reason
                    will have the same effect as a Registrant electing not to
                    answer the applicable Tournament Day’s question(s).
                  </u>
                </Text>

                <Text my={paragraphIndent}>
                  You may change your answer one or more times prior to the
                  deadline for answering question(s) for a given Tournament Day.
                  If you change your answer, the last answer that you inputted
                  will be considered your final answer and will be used for
                  purposes of Game Play.
                </Text>

                <Text my={paragraphIndent}>
                  Do NOT include any commentary along with your answer(s) to
                  question(s) fora given Tournament Day. Revisiting the sample
                  question from Section 5, your answer should consist solely of
                  a whole number. If Sponsor cannot readily determine your
                  answer, it will be deemed by Sponsor to be incorrect. If your
                  answer is accompanied by content that Sponsor determines in
                  its sole discretion is obscene, hateful, offensive or
                  otherwise inappropriate, it will be deemed by Sponsor to be
                  incorrect and will result in disqualification from the Game
                  and may result in Sponsor prohibiting you from participating
                  in future promotions offered by Sponsor or its
                  affiliated/related entities as well as seeking damages to the
                  fullest extent permitted by law.
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Scoring:
                    </Mark>
                  </Text>
                </ListItem>

                <Text my={paragraphIndent}>
                  The Registrant with the highest Point total for each Swing
                  will win a Swing Prize (as defined in Section 10 below). There
                  is a limit of one (1) Swing Prize per person. Accordingly, if
                  the Swing 1 Prize winner has the highest Point total in Swing
                  2, the Registrant with the next highest Point total in Swing 2
                  will win the Swing 2 Prize.
                </Text>

                <Text my={paragraphIndent}>
                  In the event of a tie for either Swing Prize (that is, two [2]
                  or more Registrants have the same highest Point total for a
                  Swing) the Swing Prize Winner will be determined based on
                  which of the tied Registrants correctly answered more or the
                  most Tournament Day’s questions with a value of three (3)
                  Points. Should a tie still remain, the Swing Prize Winner will
                  be the Registrant who is selected in a random drawing
                  conducted by Administrator from among those still tied.
                </Text>

                <Text my={paragraphIndent}>
                  The odds of winning the Swing Prize for a given Swing depends
                  on the knowledge of a Registrant regarding Hologic WTA Tour
                  tennis tournaments taking place during that Swing (and WTA
                  Tour players competing same) as compared to that of other
                  Registrants.
                </Text>

                <Text my={paragraphIndent}>
                  If a Registrant accumulates a Streak of fifty (50) or more
                  (beginning on July 29, 2024), he/she will win the Streak
                  Prize. The length of a Streak Prize Winner’s Streak (provided
                  that it is at least fifty [50]) will in no way affect the
                  Streak Prize won: a given Registrant can only win one (1)
                  Streak Prize. If more than one (1) Registrant accumulates a
                  Streak of fifty (50) or more (beginning on July 29, 2024), the
                  Streak Prize will be divided equally among all such
                  Registrants.
                </Text>

                <Text my={paragraphIndent}>
                  The odds of winning the Streak Prize depends on the knowledge
                  of a Registrant regarding Hologic WTA Tour tennis tournaments
                  taking place between July 29, 2024 and the end of the
                  Promotion Period (and WTA Tour players competing same.)
                </Text>

                <Text my={paragraphIndent}>
                  From time to time during the Promotion Period, the Website
                  will feature a leader board listing all Registrants by
                  username and Point total and, beginning after July 29, 2024,
                  the longest active Streak (“ Leader Board”). Notwithstanding
                  the contents of any Leader Board, Sponsor reserves the right
                  to revise the Point totals, Streak status and rankings of
                  Registrants listed on a given Leader Board at any time prior
                  to the awarding of prizes.
                </Text>

                <Text my={paragraphIndent}>
                  <u>
                    A REGISTRANT IS NOT A POTENTIAL WINNER OF ANY PRIZE UNLESS
                    AND UNTIL HE/SHE IS PERSONALLY NOTIFIED TO SUCH EFFECT BY
                    SPONSOR/ADMINISTRATOR AS PER SECTION 11 BELOW. THE CONTENTS
                    OF LEADER BOARDS ARE NOT IN ANY WAY BINDING UPON SPONSOR OR
                    ADMINISTRATOR UNDER ANY LEGAL THEORY AND WILL NOT BE
                    ACCEPTED AS PROOF OF WINNING ANY PRIZE.
                  </u>
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Additional Restrictions on Registration/Game Play:
                    </Mark>
                  </Text>
                </ListItem>

                <Text my={paragraphIndent}>
                  Registrations/Game Plays must be manually key stroked by
                  Registrant; use of automated entry devices or programs, or
                  submissions by third parties on a Registrant’s behalf are
                  prohibited.
                </Text>

                <Text my={paragraphIndent}>
                  Registrations/Game Plays that do not conform to or that
                  violate these Official Rules or are submitted in any manner
                  other than as stated in these Official Rules will be
                  disqualified.
                </Text>

                <Text my={paragraphIndent}>
                  Proof of submission of Registration/Game Play does not
                  constitute proof of receipt of same.
                </Text>

                <Text my={paragraphIndent}>
                  Sponsor/Administrator will acknowledge receipt of
                  Registration/Game Play as required by applicable law. Such
                  acknowledgment of receipt of Registration/Game Play does not
                  constitute any representation as to eligibility, notification
                  of winning nor is it otherwise binding upon Sponsor or
                  Administrator under any legal theory.
                </Text>

                <Text my={paragraphIndent}>
                  Registrations/Game Plays that are incomplete, garbled,
                  corrupted, jumbled, delayed, inaccessible, improperly recorded
                  (or not recorded at all) or unintelligible for any reason
                  beyond the reasonable control of the Promotion Entities,
                  including, but not limited to, computer or network malfunction
                  or congestion, are void and will not be accepted.
                </Text>

                <Text my={paragraphIndent}>
                  By accepting prize(s) (except where prohibited by law) Winner
                  consents to use by Sponsor and its assignees/designees of
                  their name, city, state/province/country, likeness, image,
                  and/or voice for purposes of advertising, promotion, and
                  publicity in any and all media now or hereafter known,
                  throughout the world, without further compensation,
                  notification, permission, or approval. For residents of the
                  United States and Canada, the foregoing consent shall last in
                  perpetuity; for residents of the United Kingdom, the foregoing
                  consent shall last for the Promotion Period and for two (2)
                  years thereafter.
                </Text>

                <Text my={paragraphIndent}>
                  If you choose to participate in the Game by using your
                  wireless mobile device, data rates may apply for each message
                  sent or received from your handset according to the terms and
                  conditions of your service agreement with your wireless
                  carrier. Other charges may apply (such as normal airtime and
                  carrier charges as well as charges for wireless internet
                  access) and may appear on your mobile phone bill or be
                  deducted from your prepaid account balance. Wireless
                  carriers&apos; rate plans may vary, and you should contact
                  your wireless carrier for more information on messaging rate
                  plans and charges relating to your participation in this Game.
                  Mobile device service may not be available in all areas. Check
                  your phone&apos;s capabilities for specific instructions. The
                  Sponsor will not reimburse any charges incurred by Registrants
                  to register for or participate in the Game.
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Prizes and Approximate Retail Values (“ARV”):
                    </Mark>
                  </Text>
                </ListItem>

                <Text my={paragraphIndent}>
                  &quot;Swing Prize&quot; (2 Winners, 1 per Swing): Swing Prize
                  Winner will receive a trip for two (2) to a 2025 Hologic WTA
                  Tour tennis tournament of Swing Prize Winner’s choice from
                  among three (3) options provided by Sponsor, beginning in
                  February 2025 (“ Swing Prize Tournament”): (i) roundtrip
                  economy air travel for two (2) from a major/international
                  airport closest to Swing Prize Winner&apos;s place of
                  residence (selected by Sponsor in its sole discretion); (ii)
                  two (2) nights hotel accommodations (one (1) standard room,
                  double occupancy) at a hotel selected by Sponsor in its sole
                  discretion, (iii) two (2) tickets for two (2) sessions at the
                  Swing Prize Tournament (Sponsor will determine seat type and
                  location at the Swing Prize Tournament; tickets are governed
                  by a revocable, nontransferable license, including prohibition
                  on sale/re-sale, exchange, transfer, assignment, auction,
                  barter, donation, placement into commerce or other form of
                  conveyance; venue restrictions also apply) and (iv) ground
                  transportation to and from the destination airport and hotel.
                </Text>

                <Text my={paragraphIndent}>
                  <Mark fontWeight='700'>Swing Prize Trip Restrictions:</Mark>{' '}
                  Meals, beverages, gratuities, incidental expenses and all
                  other costs and expenses relative to acceptance/use of prize
                  not specified herein are not included and are solely the Swing
                  Prize Winner&apos;s responsibility. All arrangements are
                  subject to change, availability, and other restrictions. Swing
                  Prize trip arrangements cannot be changed by Swing Prize
                  Winner/travel companion. Swing Prize Winner/travel companion
                  must travel on the same itinerary and at the same time; hotel
                  and airline/flights will be determined by Sponsor in its sole
                  discretion. Travel companion of Swing Prize Winner must
                  complete, sign and return a Liability Release and Publicity
                  Release (where lawful) prior to issuance of travel documents.
                  If Swing Prize Winner&apos;s travel companion is a Minor,
                  Winner must be Minor&apos;s parent/legal guardian and must
                  complete Release (and any other required documentation) on
                  behalf of Minor. Swing Prize Winner must respond promptly to
                  Sponsor&apos;s requests regarding travel arrangements. Swing
                  Prize Winner and travel companion must act in an appropriate
                  and well-behaved manner at all times, including but not
                  limited to during attendance at Swing Prize Tournament (e.g.,
                  Swing Prize Winner and/or travel companion being intoxicated,
                  abusing/harassing Swing Prize Tournament players, posing a
                  safety risk to themselves or others, or violating policies of
                  Swing Prize Tournament and/or venue hosting same). SWING PRIZE
                  WINNER/TRAVEL COMPANION MUST TRAVEL ON THE DATES, AND AT THE
                  TIME SPECIFIED BY THE SPONSOR SO AS TO ATTEND THE SELECTED
                  SWING PRIZE TOURNAMENT; IF SWING PRIZE WINNER IS UNAVAILABLE
                  ON SUCH DATES FOR ANY REASON, THE SWING PRIZE WILL BE
                  FORFEITED WITHOUT COMPENSATION OF ANY KIND AND AN ALTERNATE
                  WINNER WILL BE SELECTED IN THE MANNER SET FORTH ABOVE. No
                  responsibility is assumed by the Sponsor for any cancelled,
                  delayed, suspended or rescheduled flights and events once any
                  tickets have been issued in relation to the prize for any
                  reason whatsoever beyond its control. Swing Prize
                  Winner/travel companion must provide own transportation
                  to/from airport of departure. Swing Prize Winner/travel
                  companion are solely responsible for obtaining any/all
                  necessary travel documentation/requirements (including but not
                  limited to valid photo I.D., passport, etc., to the extent
                  applicable) before departure. Airline carrier and hotel
                  regulations and conditions apply. Prize is not valid for
                  upgrades. All tickets issued in conjunction with the Swing
                  Prize are not eligible for frequent flyer miles, hotel award
                  points or other “perks.” If air travel is not needed, in the
                  sole opinion of the Sponsor/Administrator, ground
                  transportation (or a stipend in an amount of Sponsor’s sole
                  choosing to be used for ground transportation) may be
                  substituted for air travel, and there will be no cash
                  alternative or substitute prize. If Swing Prize Winner elects
                  to accept prize without a travel companion, the Swing Prize
                  will be awarded in increments appropriate for a single
                  individual, the Swing Prize Winner him/herself (including only
                  one (1) ticket to Swing Prize Tournament) and there will be no
                  cash alternative or substitute prize.
                </Text>

                <Text my={paragraphIndent}>
                  <Mark fontWeight='700'>
                    ARV of each Swing Prize is $4,500 (USD), £3,614**.
                  </Mark>{' '}
                  Actual final value of Swing Prize may be higher or lower based
                  on various factors, including selected and approved Swing
                  Prize Tournament, location of Swing Prize Winner and time of
                  travel. If actual value of Swing Prize is less than $4,500
                  (USD), no additional compensation will be provided
                </Text>

                <Text my={paragraphIndent}>
                  <Mark fontWeight='700'>
                    Streak Prize: $250,000 (USD), £202,118**
                  </Mark>{' '}
                  Streak Prize will be awarded in the form of a check or direct
                  deposit to Streak Prize Winner’s account at an approved
                  financial institution, in Sponsor’s sole discretion.
                </Text>

                <Text fontWeight='700' my={paragraphIndent}>
                  THE TOTAL ARV OF ALL PRIZES IN THE GAME IS $259,000 (USD),
                  £208,029**.
                </Text>
                <Text fontWeight='700' my={paragraphIndent}>
                  **Prize values in British Pound Sterling reflect currency
                  exchange rate as of date of finalization of Official Rules.
                  Actual prize value may vary.
                </Text>

                <Text>
                  <Mark fontWeight='700'>
                    LIMIT OF ONE (1) SWING PRIZE AND ONE (1) STREAK PRIZE PER
                    REGISTRANT. No substitution or transfer of prize except with
                    Sponsor’s prior written permission.
                  </Mark>{' '}
                  Sponsor in its sole discretion may award a substitute prize of
                  equal or greater value if Swing Prize is unavailable at time
                  of awarding for a justified reason. Prizes are NOT redeemable
                  or exchangeable for cash or credit (excluding Streak Prize).
                  Any images of prize in advertising are for illustrative
                  purposes only and actual prize may differ from such images.{' '}
                  <Mark fontWeight='700'>
                    All applicable federal, state, foreign, provincial, and
                    local taxes are the sole responsibility of Winner except
                    where prohibited by local laws in a given country. Winners
                    will receive either (i) if a U.S. resident, a 1099 tax form
                    reflecting actual value of prize as it is valued at $600
                    (USD) or over, or (ii) if not a U.S. resident, an IRS Form
                    1042-S, W-8Ben, or any other applicable tax forms. A
                    non-U.S. resident Winner may be responsible for U.S. foreign
                    withholding tax on prize(s) in addition to any taxes that
                    may be levied by his/her country of residence on value of
                    prize(s) won . Should a Swing Prize Winner also win the
                    Streak Prize, the taxable amount will reflect the combined
                    value of the Swing Prize
                    <Mark textDecor='underline'>and</Mark>
                    the Streak Prize. All taxes are the sole responsibility of
                    the Winners.
                  </Mark>
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Winner Notification:
                    </Mark>{' '}
                    Winners will be contacted by email. Swing 1 Prize Winner
                    will be contacted on or about August 7, 2024. Swing 2 Prize
                    Winner and Streak Prize Winner(s) [if any] will be contacted
                    on or about November 13, 2024. Winners will be required to
                    sign and return by email, within three (3) business days of
                    notification, an Affidavit/Certificate of Eligibility,
                    Release and Indemnification, a Publicity Release where
                    allowed by law, and (if a resident of the United States or
                    Canada) the necessary paperwork for a background check
                    (collectively, “<Mark fontWeight='700'>Game Documents</Mark>
                    ”). If the Affidavit/Certificate of Eligibility, Release and
                    Indemnification, and Publicity Release, or any other
                    portions of the Game Documents are not returned to Sponsor
                    within the specified time period and the Winner cannot be
                    contacted after two (2) attempts within three (3) business
                    days of first attempted notification, the applicable Winner
                    may be disqualified. If a Winner is disqualified for any
                    reason, there will be no prize of any kind awarded to
                    him/her. If a Swing Prize Winner is disqualified, an
                    alternate Winner will be selected (that is, the Registrant
                    with the next highest Point total in the applicable Swing);
                    if a Streak Prize Winner is disqualified, the Streak Prize
                    will be awarded equally among the remaining Streak Prize
                    Winner(s) and, if there are not any remaining Streak Prize
                    Winners, the Streak Prize will NOT be awarded.
                  </Text>
                </ListItem>

                <Text>
                  <Mark fontWeight='700' textDecor='underline'>
                    If a Canadian resident has the highest Point total in a
                    Swing or has a Streak,
                  </Mark>
                  he/she will be required to correctly answer a mathematical
                  skill-testing question without mechanical or other assistance
                  of any kind, within a predetermined time limit, as a
                  pre-condition to becoming the potential winner of the
                  prize(s).
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Conditions and Limitations of Liability:
                    </Mark>
                    Subject to liability in relation to any “ Non-Excludable
                    Guarantees” as defined below and subject to the below
                    paragraph relating to Registrants from the United Kingdom
                    and as long as this is not prohibited by mandatory local law
                    or public policy by participating in this Game, Registrants
                    agree to release and hold harmless Sponsor and the Promotion
                    Entities, as well as each of their respective officers,
                    directors, employees, agents, and representatives, and each
                    of their respective successors, representatives, and
                    assigns, as well as Meta Platforms, Inc. and X Corp.
                    (collectively, the “
                    <Mark fontWeight='700'>Released Parties</Mark>”) from any
                    and all actions, claims, injury, loss, or damage arising in
                    any manner, directly or indirectly, from participation in
                    this Game and/or acceptance, receipt, possession, use, or
                    misuse of any prize. All federal, state, provincial, and
                    local laws and regulations apply.
                  </Text>
                </ListItem>

                <Text my={paragraphIndent}>
                  Nothing in these Official Rules excludes or modifies or
                  purports to limit, exclude or modify any statutory consumer
                  guarantees or any implied condition or warranty the exclusion
                  of which from these Official Rules would contravene any
                  statute or cause any part of these Official Rules to be void
                  (“
                  <Mark fontWeight='700'>Non-Excludable Guarantees</Mark>”) or
                  any other liabilities which shall not be excluded under
                  mandatory local law or public policy. Subject to the
                  limitations in the preceding sentence, the Sponsor excludes
                  from these Official Rules all conditions, warranties and terms
                  implied by statute, general law or custom. Except for
                  liability in relation to a Non-Excludable Guarantee or any
                  other liabilities which shall not be excluded under mandatory
                  local law or public policy, the Released Parties exclude all
                  liability whether arising in tort (including without
                  limitation negligence), contract or otherwise, for any
                  personal injury or any other loss or damage (including without
                  limitation loss of opportunity or loss of profits), whether
                  direct, indirect, special or consequential, arising in any way
                  out of the Game, including for failed, partial, or garbled
                  computer transmissions, or for technical failures of any kind
                  affecting the Game in any manner, including but not limited to
                  electronic malfunction or damage of any network, hardware, or
                  software. The foregoing also includes any malfunction, error,
                  or failure deemed by Sponsor or Administrator in the
                  discretion of either party to impact the availability or
                  accessibility of Registrations and/or Game Plays submitted by
                  Registrants or the transmission or receipt of winner
                  notification or any Prize. If for any reason the Game is not
                  capable of running as planned, including infection by computer
                  virus, bugs, tampering, unauthorized intervention, fraud, or
                  technical failures beyond Sponsor’s control that corrupt or
                  affect the administration, security, fairness, integrity, or
                  proper conduct of the Game, Sponsor reserves the right, in its
                  sole discretion, to disqualify any individual who tampers with
                  the Registration and/or Game Play process.{' '}
                  <Mark fontWeight='700'>
                    THE PRECEDING EXPRESSLY INCLUDES BUT IS NOT LIMITED TO ANY
                    EVENT BEYOND SPONSOR’S REASONABLE CONTROL THAT INTERFERES
                    WITH OR INTERRUPTS THE ORIGINALLY PLANNED CONDUCT OF ONE (1)
                    OR MORE HOLOGIC WTA TOUR TENNIS TOURNAMENTS DURING THE
                    PROMOTION PERIOD.
                  </Mark>{' '}
                  In such event, Sponsor reserves the right to conduct the Game
                  and make the prizes available to be won in a manner which is
                  fair, appropriate, and consistent with these Official Rules,
                  as determined by Sponsor in its sole discretion. Notice of
                  termination, modification, or suspension of Game will be
                  posted on the Website. No responsibility is assumed for any
                  error, omission, interruption, deletion, defect, delay in
                  operation or transmission, communications line failure, theft
                  or loss, destruction or unauthorized access to, or alteration
                  of Registrations and/or Game Plays; or any problems or
                  technical malfunctions of any telephone network or lines,
                  computer online systems, servers, or providers, computer
                  equipment, software, failure of any email, Registration or
                  Game Play to be received on account of technical problems or
                  traffic congestion on the Internet or at any website, or any
                  combination thereof, including any injury or damage to any
                  person&apos;s computer or wireless mobile device related to or
                  resulting from participation in or downloading any materials
                  from this Game. Failure of Sponsor or Administrator to enforce
                  any provision of these Official Rules shall not constitute the
                  waiver of such provision.
                </Text>

                <Text my={paragraphIndent}>
                  You agree not to damage or cause interruption of the Game
                  and/or prevent others from registering for and/or
                  participating in the Game. CAUTION: ANY ATTEMPT TO DAMAGE ANY
                  ONLINE SERVICE OR WEBSITE OR UNDERMINE THE LEGITIMATE
                  OPERATION OF THE GAME, OR TO DEFRAUD SPONSOR OR ADMINISTRATOR
                  IN ANY MANNER RELATIVE TO REGISTRATION FOR AND/OR
                  PARTICIPATION IN THE GAME VIOLATES CRIMINAL OR CIVIL LAWS. IF
                  SUCH AN ATTEMPT IS MADE, SPONSOR MAY DISQUALIFY ANY PERSON
                  MAKING SUCH ATTEMPT, PREVENT SUCH PERSON FROM PARTICIPATING IN
                  FUTURE PROMOTIONS OFFERED BY SPONSOR OR ANY AFFILIATED/RELATED
                  ENTITIES AND SEEK DAMAGES TO THE FULLEST EXTENT OF THE LAW.
                </Text>

                <Text my={paragraphIndent}>
                  In respect of Registrants from the United Kingdom,
                  notwithstanding anything to the contrary, nothing in these
                  Official Rules shall exclude or limit a party’s liability for
                  death or personal injury resulting from that party’s
                  negligence, for fraud or fraudulent misrepresentation or for
                  any other matter where liability may not as a matter of law be
                  limited.
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Disputes:
                    </Mark>{' '}
                    For Registrants who are legal residents of the United
                    Kingdom: These Official Rules shall be governed by the laws
                    of their country of legal residence, and the parties submit
                    to the non-exclusive jurisdiction of the courts of their
                    country of legal residence.
                  </Text>
                </ListItem>

                <Text my={paragraphIndent}>
                  For Registrants who are legal residents of the U.S. or Canada:
                  These Official Rules shall be governed by the law of the U.S.
                  state of Delaware and the parties submit to the non-exclusive
                  jurisdiction of the courts of the U.S. state of Delaware.
                </Text>

                <Text my={paragraphIndent}>
                  Except where prohibited, Registrant agrees that: (a) any and
                  all disputes, claims, or causes of action arising out of or
                  connected with this Game or any prize awarded shall be
                  resolved individually, without resort to any form of class
                  action, and, to the extent permitted by compulsory local law
                  in the relevant State or Territory of the Registrant’s country
                  of residence, expressly waiving any right of
                  <i>forum non conveniens, </i>change of venue or the like; (b)
                  any and all claims, judgments, or awards, to the extent
                  permitted by compulsory local law in Registrant’s country of
                  residence, shall be limited to actual out-of-pocket costs
                  incurred, including costs associated with participating in
                  this Game, but in no event attorneys&apos; fees; and (c) under
                  no circumstances will Registrant be permitted to obtain awards
                  for, and Registrant hereby waives all rights to claim,
                  indirect, punitive, incidental, and consequential damages and
                  any other damages, other than for actual out-of-pocket
                  expenses, and any and all rights to have damages multiplied or
                  otherwise increased, except where compulsory local law in
                  Registrant’s country of residence provides otherwise.
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Severability:
                    </Mark>{' '}
                    If any part of these Official Rules shall be declared
                    invalid or unenforceable by a court of competent
                    jurisdiction, it shall not affect the validity of the
                    balance of these Official Rules. In such circumstance, the
                    provision declared invalid or unenforceable shall be
                    re-drafted as closely to the original provision as allowed
                    by law, so as to give effect to Sponsor’s intent.
                  </Text>
                </ListItem>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Language Discrepancies:
                    </Mark>{' '}
                    Should there be any discrepancy between the English language
                    version of these Official Rules and the version of the
                    Official Rules as translated into another language, the
                    English language version shall prevail, govern, and control
                    in all respects.
                  </Text>
                </ListItem>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Data Protection; Privacy Policy:
                    </Mark>{' '}
                    Your personal information will be collected for the purposes
                    of conducting this Game. Use of personal information shall
                    be subject to the privacy policy located at{' '}
                    <Link
                      href='https://www.wtatennis.com/privacy-policy'
                      textDecor='underline'
                    >
                      https://www.wtatennis.com/privacy-policy
                    </Link>{' '}
                    (“<Mark fontWeight='700'>Privacy Policy</Mark>”). The
                    Privacy Policy contains information about how an Registrant
                    can access or correct personal information held about them
                    or to make a privacy complaint. Please note that your
                    personal information will be transferred to overseas
                    recipients, including in countries such as the United
                    States. The methods used to protect and safeguard your
                    personal information in the event of such transfers are set
                    out in the Privacy Policy.
                  </Text>
                </ListItem>

                <Text my={paragraphIndent}>
                  The following paragraph applies to Registrants from the United
                  Kingdom: Any personal information collected from Registrants
                  will be used by Sponsor/Administrator at the direction of
                  Sponsor’s appointed representative as set forth below.
                  Personal information collected from Registrants will be used
                  by Sponsor/Administrator solely in connection with the Game
                  and will not be disclosed to any third party or used for any
                  other purpose except for the purpose of operating the Game,
                  honoring/fulfilling an Registrant’s request to “opt-in” to
                  receive promotional emails from Sponsor (if and as
                  applicable), fulfilling the prizes where applicable, and any
                  other use mentioned within this clause. The declared prize
                  winner’s surname, county/region and country will be made
                  available on request in accordance with Section 17 (Winners’
                  List) below. The legal basis for processing the data is
                  performance of a contract and – to the extent that does not
                  apply – the Sponsor’s legitimate interests (see Article 6,
                  paragraph 1, lit b) and f) of the UK GDPR). The Sponsor’s
                  legitimate interests in this instance include the marketing of
                  its brand and the Hologic WTA Tour tennis tournaments via the
                  Game. The personal information may be transferred outside the
                  United Kingdom to overseas recipients, including in countries
                  such as the United States. The duration of the retention of
                  the information is until March 1, 2025 except in the case of
                  the Winners and those Registrants who “opt-in” to receive
                  promotional emails from Sponsor (if and as applicable).
                  Registrants have a right to access, correct and have their
                  personal data deleted from Sponsor’s database and to set the
                  consequences of his/her death on his/her personal
                  data.&nbsp;Registrants can exercise these rights by sending a
                  written request to WTA Ventures Operations, LLC, c/o Willans
                  Data Protection Limited, 34 Imperial Square, Cheltenham,
                  Gloucestershire GL50 1QZ UNITED KINGDOM.
                </Text>

                <Text my={paragraphIndent}>
                  Personal information includes name, email address, and
                  likeness and will be used as set out in these Official Rules
                  including: (a) to conduct the Game as described in these
                  Official Rules; (b) to contact the potential winners; (c) to
                  honor/fulfill requests to “opt-in” to receive promotional
                  emails from Sponsor (if and as applicable) and (d) fulfill the
                  prizes.
                </Text>

                <ListItem my={paragraphIndent}>
                  <Text>
                    <Mark fontWeight='700' textDecor='underline'>
                      Winners’ List:
                    </Mark>{' '}
                    The surname, county/region and country of residence of each
                    prize winner will be made available on request unless a
                    given winner objects to this. To request the surname,
                    county/region and country of residence of winners, mail a
                    self-addressed, stamped envelope to: “
                    <i>WTA Pick ‘Em &amp; Streaks Game Winners</i>”, c/o
                    Probability, PO Box 1559, Social Circle, GA 30025-1559. All
                    such requests must be received by November 25, 2024. Persons
                    other than U.S. residents may omit return postage. To object
                    to your surname, county/region and country of residence
                    being made available in this manner in the event you are a
                    winner please let us know prior to accepting any prize. In
                    such circumstances these details may still be provided to
                    the UK’s Advertising Standards Authority or other relevant
                    regulators on request.
                  </Text>
                </ListItem>
              </OrderedList>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
