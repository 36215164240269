import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { api } from '@/services/api.service';
import appSlice from '@/store/slices/app.slice';

const rootReducer = combineReducers({
  app: appSlice,
  [api.reducerPath]: api.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
