export function dirtyValues(
  dirtyFields: object | boolean,
  allValues: object,
): object {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
}
