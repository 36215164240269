import { API_BASE_URL, TENANT_CODE } from '@/utils/constants';

export const checkNickname = async (
  nickname: string,
): Promise<{ user_exists: boolean }> => {
  try {
    const valid = await fetch(
      `${API_BASE_URL}/user/username/check?username=${nickname}`,
      {
        headers: {
          'X-TENANT-ID': TENANT_CODE,
        },
      },
    );
    return valid.json();
  } catch (err: any) {
    throw err;
  }
};
