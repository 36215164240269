import * as yup from 'yup';

import { TFunc, tKeys } from '@/localization';

import { checkOnBannedPhrase } from '@/utils/bannedPhrase';
import { emailValidityTest, nicknameValidityTest } from '@/utils/cacheTest';
import { isEmailFormatValid } from '@/utils/regexp';

import { UserProfileType } from '@/types/domain';

export const profileSchema = (t: TFunc, user: UserProfileType | undefined) => {
  return yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(t(tKeys.emailIsRequired))
      .concat(isEmailFormatValid(t))
      .test(
        'email-exists',
        t(tKeys.emailAddressAlreadyInUse),
        (value) => emailValidityTest(value as string, user?.email) as any,
      ),
    firstName: yup
      .string()
      .trim()
      .min(3, t(tKeys.firstNameMusBeAtLeast))
      .max(100, t(tKeys.firstNameMustNotExceed))
      .required(t(tKeys.firstNameIsRequired)),
    lastName: yup
      .string()
      .trim()
      .min(3, t(tKeys.laststNameMusBeAtLeast))
      .max(100, t(tKeys.laststNameMustNotExceed))
      .required(t(tKeys.laststNameIsRequired)),
    username: yup
      .string()
      .trim()
      .required(t(tKeys.usernameIsRequired))
      .test(
        'banned-phrase',
        t(tKeys.inappropriateWordsNotAllowed),
        (value) => !checkOnBannedPhrase(value),
      )
      .test(
        'username-exists',
        t(tKeys.usernameAlreadyInUse),
        (value) => nicknameValidityTest(value, user?.username) as any,
      ),
    country: yup.string().trim().required(t(tKeys.countryIsRequired)),
  });
};
