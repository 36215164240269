import { Button, Text, useDisclosure, useTheme } from '@chakra-ui/react';

import { UserIcon } from '@/assets/icons/UserIcon';

import { ProfileModal } from '@/components/Header/components/ProfileModal';

import { useUserContext } from '@/context/user.context';

import { trackEvent } from '@/utils/analyticTracks';
import { HIDE_PROFILE } from '@/utils/constants';

export const ProfileButton = () => {
  const { user } = useUserContext();
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onButtonClick = () => {
    trackEvent({
      event: 'button_clicked',
      button_text: 'Username',
      location: 'Gamezone Lobby Navigation',
    });

    onOpen();
  };

  return (
    (user && !HIDE_PROFILE && (
      <>
        <Button
          alignItems='center'
          color={theme.colors.sidebar.text}
          display='flex'
          fontSize='17px'
          fontWeight='700'
          justifyContent='flex-start'
          leftIcon={<UserIcon />}
          maxW='100%'
          onClick={onButtonClick}
          px='5px'
          variant='unstyled'
          w='fit-content'
        >
          <Text color={'black'} noOfLines={1}>
            {user.username}
          </Text>
        </Button>

        <ProfileModal {...{ isOpen, onClose }} />
      </>
    )) ||
    null
  );
};
