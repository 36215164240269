import { API_BASE_URL, TENANT_CODE } from '@/utils/constants';

export const checkEmail = async (
  email: string,
): Promise<{ isEmailFree: boolean }> => {
  try {
    const valid = await fetch(
      `${API_BASE_URL}/user/email/check?email=${email}`,
      {
        headers: {
          'X-TENANT-ID': TENANT_CODE,
        },
      },
    );
    return valid.json();
  } catch (err: any) {
    throw err;
  }
};
