import React from 'react';

import { Box } from '@chakra-ui/react';

import { useAuth } from '@/hooks/useAuth';
import { useLangDetection } from '@/hooks/useLangDetection';

export const AppWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  useAuth();
  useLangDetection();

  return <Box position='relative'>{children}</Box>;
};
